import SubscriptionModule from '../modules/Subscription'

const Subscription = () => {
    
    return <div className='content'>
            <SubscriptionModule />
        </div>
}

export default Subscription
