import chatIcon from '../assets/img/icons/chat_icon.svg'
import friendsIcon from '../assets/img/icons/friends_icon.svg'
import recordChatIcon from '../assets/img/icons/record_chat_icon.svg'
import cloudIcon from '../assets/img/icons/cloud_icon.svg'
import Button from "../ui/Button"
import { useTranslation } from 'react-i18next'

const VideoSubscribeCta = () => {
    const { t } = useTranslation()
    return <div className='card-widget card-widget__subscribe-card'>
        <span className='card-widget__title h2'>{t('videos.record_videos_subscription')}</span>
        <div className='benefits-col'>
            <div className='benefits-col__row'>
                    <img src={chatIcon} alt="chat icon" />
                    <span className='subtitle_big'>{t('videos.access_individual_chats')}</span>
            </div>
            <div className='benefits-col__row'>
                    <img src={friendsIcon} alt="friends icon" />
                    <span className='subtitle_big'>{t('videos.add_friends_unlimitedly')} </span>
            </div>
            <div className='benefits-col__row'>
                    <img src={recordChatIcon} alt="recird chat icon" />
                    <span className='subtitle_big'>{t('videos.ability_record_chat')} </span>
            </div>
            <div className='benefits-col__row'>
                    <img src={cloudIcon} alt="cloud icon" />
                    <span className='subtitle_big'>{t('videos.save_videos_cloud')}</span>
            </div>
            <Button to="/subscription"
                type="primary" 
            >{t('videos.subscribe_now')}</Button>
        </div>
    </div>
}
export default VideoSubscribeCta