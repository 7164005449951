const ActionTypes = Object.freeze({
    FRIENDS_LOADING: 'FRIENDS_LOADING',
    FRIENDS_ERROR: 'FRIENDS_ERROR',
    GETTING_FRIENDS_INFO: 'GETTING_FRIENDS_INFO',
    GETTING_FRIENDS_REUQESTS: 'GETTING_FRIENDS_REUQESTS',
    ACCEPT_FRIEND: 'ACCEPT_FRIEND',
    SEND_FRIEND_REQUEST: 'SEND_FRIEND_REQUEST',
    DELETE_FRIEND: 'DELETE_FRIEND',
    ALL_USERS: 'ALL_USERS',
});
export default ActionTypes