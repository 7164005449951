import Input from "../ui/Input";
import { useForm } from 'react-hook-form';
import Button from "../ui/Button";
import { apiConfig } from '../api/api.config.js';

const ForgotPassword = () => {
    const { register, handleSubmit } = useForm();
    
    const onSubmit = async (data) => {
        try {
            const response = await fetch(apiConfig.url + '/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data), 
            });

            if (response.ok) {
                const result = await response.json();
                alert(result.message);
            } else {
                const error = await response.json();
                console.error('There was an error!', error);
                alert('Failed. Please try again.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            alert('Failed. Please try again.');
        }
    };

    return (
        <div className='content'>
            <div className="form-wrap">
                <div className="popup__change-email">
                    <h2 className='popup__heading h2'>Recover your password</h2>
                    <span className='popup__description subtitle_big'>We will send you a confirmation code to change your password.</span>
                    <form className='popup__form' onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            placeholder="Enter your E-mail"
                            name="email"
                            type="text"
                            register={register}
                        />
                        <Button
                            tag="button" 
                            type="primary"
                        >
                            Confirm
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
