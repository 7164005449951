import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { http } from '../helpers';
import ActionTypes from '../store/action-types';

export function useAuth() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      http.get("/me")
        .then(res => {
          dispatch({ type: ActionTypes.LOGIN_USER, currentUser: res.data });
        })
        .catch(error => {
          console.error("Failed to fetch user data", error);
          dispatch({ type: ActionTypes.LOGOUT_USER });
        });
    }
  }, [dispatch]);
}