import { Link } from 'react-router-dom'
import additionalInfo from '../assets/img/icons/additional_infoIcon.svg'
import { formatDateShort, videoStorageUrl } from '../utils/Utils'
import { useDispatch } from 'react-redux'
import { deleteVideo } from '../store/actions/saved-videos'
import { useTranslation } from 'react-i18next'


const VideoCard = ({data}) => {
    const videoSize = (data?.size / 1024 / 1024).toFixed(2)
    const videoDate = formatDateShort(data.updated_at)
    const downloadUrl = videoStorageUrl + data?.url

    const dispatch = useDispatch()

    const onDelete = (e) => {
        e.preventDefault()
        dispatch(deleteVideo(data.id))
    }
    const downloadVideo = e => {
        e.preventDefault()
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'parrot-video.mp4');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const { t } = useTranslation()
    
    return <div className='video-item'>
        <div className='video-item__info'>
            <div className='video-item__info-line'>
                <span className='selector subtitle_medium'>{t('subscriptions.date')}</span>
                <span className='text text_medium'>{videoDate}</span>
            </div>
            <div className='video-item__info-line'>
                <span className='selector subtitle_medium'>{t('videos.size_title')}</span>
                <span className='text text_medium'>{videoSize} {t('videos.video_size')}</span>
            </div>
        </div>
        <div className='additional-btn'>
            <img src={additionalInfo} alt="additional info button" />
            <ul className='video-item__dropdown dropdown'>
                <li><a href={downloadUrl} target="_blank" rel="nofollow">{t('general.download')}</a></li>
                <li><Link to="/" onClick={e => onDelete(e)}>{t('general.delete')}</Link></li>
            </ul>
        </div>
    </div>
}
export default VideoCard