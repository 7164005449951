const ActionTypes = Object.freeze({
    UPDATE_PROFILE_INFO: 'UPDATE_PROFILE_INFO',
    UPDATE_PROFILE_LOADING: 'UPDATE_PROFILE_LOADING',
    UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
    UPLOAD_PHOTO_LOADING: 'UPLOAD_PHOTO_LOADING',
    UPLOAD_PHOTO_ERROR: 'UPLOAD_PHOTO_ERROR',
    UPDATE_PROFILE_PHOTO: 'UPDATE_PROFILE_PHOTO',
    GETTING_USER_INFO: 'GETTING_USER_INFO',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    PASSWORD_ERROR: 'PASSWORD_ERROR',
    PASSWORD_LOADING: 'PASSWORD_LOADING',
    UPDATE_EMAIL: 'UPDATE_EMAIL',
    EMAIL_ERROR: 'EMAIL_ERROR',
    EMAIL_LOADING: 'EMAIL_LOADING',
});
export default ActionTypes