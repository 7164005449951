import { useSelector, useDispatch } from 'react-redux'
import { setVideochatBreed } from '../../../store/actions/videochat'
import arrow from '../../../assets/img/icons/arrow.svg'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ChatName = () => {
    const selectedBreed = useSelector(state => state.videochat.selectedBreed)
    const dispatch = useDispatch()

    useEffect(() => {
        const cookieBreedId = Cookies.get('breed_id')
        if (cookieBreedId) {
            dispatch(setVideochatBreed(cookieBreedId))
        }
    }, [])

    const backToGeneral = () => {
        dispatch(setVideochatBreed(0))
        Cookies.remove('breed_id')
    }
    const { t } = useTranslation()

    return <div className='namechat h3'>
        {selectedBreed?.name ? 
            <span onClick={backToGeneral}>
                <img src={arrow} alt="Arrow" className='cursor-pointer' />
                <span className='subtitle_medium cursor-pointer'>{t('home.back_general')} | <b>{selectedBreed.name}</b></span>
            </span>  : 
            <span className='subtitle_medium'><b>{t('home.general_chat_title')}</b></span> 
        }
    </div>
}
export default ChatName