import { Link } from 'react-router-dom'
import arrow from '../../assets/img/icons/arrow.svg'
import SubscriptionActive from '../../modules/Subscription/components/SubscriptionActive'
import SubscriptionUnctive from '../../modules/Subscription/components/SubscriptionUnactive'
import TransactionHistory from '../../modules/Subscription/components/TransactionHistory'
// import { profileData } from '../../utils/Utils'
import { useSelector } from 'react-redux'
import { useEffect, useRef, useState} from 'react'
import { apiConfig } from '../../api/api.config.js'
import loader from '../../assets/img/icons/loader.svg'
import {useCookies} from 'react-cookie'
import arrow_dark from '../../assets/img/icons/arrow_dark.svg' 

import { useTranslation } from 'react-i18next'

const SubscriptionModule = () => {
    const [isPaidBefore, setIsPaidBefore] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.auth.currentUser);
    const isSubscribed = user?.is_subscribed;
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['theme', 'language'])

    useEffect(() => {
        const token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        fetch(apiConfig.url + '/payments', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setIsPaidBefore(data?.length ? true : false);
                setProfileData(data);
                setLoading(false);  
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);  
            });
    }, []);

    if (loading) {
       
        return <div className='videochat__loader animate-spin'><img src={loader} alt='Loader' /></div>; 
    }

    return (
        <div className="container">
            <Link to="/" className='back-link'>
                <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="link to back" />
                <span className='subtitle_big'>{t('general.back_chat')}</span>
            </Link>

            {isSubscribed ? <SubscriptionActive /> : <SubscriptionUnctive isPaidBefore={isPaidBefore} />}
            {isPaidBefore && <TransactionHistory data={profileData} />}
        </div>
    );
}
export default SubscriptionModule