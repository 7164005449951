import actionTypes from '../action-types/breedTypes';
const initialState = {
    isBreedsLoading: false,
    breedsList: null,
    breedsError: null
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.GETTING_BREEDS_INFO) {
        return {
            ...state,
            breedsList: action.breeds,
        };
    }

    if (action.type === actionTypes.BREEDS_LOADING) {
        return {
            ...state,
            isBreedsLoading: action.loading
        };
    }
    if (action.type === actionTypes.BREEDS_ERROR) {
        return {
            ...state,
            breedsError: action.error
        };
    }
    return { ...state };
};

export default reducer;