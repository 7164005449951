import { Link } from "react-router-dom"

const Button = ({type, iconLeft, iconRight, size, children, tag, className, isLoading, ...props}) => {
    const typeClass = (type === 'secondary') ? 'btn_secondary' : (type === 'ghost') ? 'btn_ghost' : 'btn_primary'
    const iconClass = (iconLeft || iconRight) ? 'btn_icons' : ''
    const sizeClass = size === 'small' ? 'btn_small' : ''
    const child = children
    const classes = className ? className : ''
    const parentIconClass = iconLeft ? 'btn_icons--parent-left' : iconRight ? 'btn_icons--parent-right' : ''

    return tag === 'button' ? 
        <button 
            className={`btn ${typeClass} ${iconClass} ${sizeClass} ${classes} ${parentIconClass}`}
            {...props}
        >
            {iconLeft ? <span className="btn_icons_left"><img src={iconLeft} alt="Icon" /></span> : ''}
            {child}
            {iconRight ? <span className="btn_icons_right"><img src={iconRight} alt="Icon" /></span> : ''}
        </button> : 
        <Link 
            className={`btn ${typeClass} ${iconClass} ${sizeClass} ${classes} ${parentIconClass}`}
            {...props}
        >
            {iconLeft ? <span className="btn_icons_left"><img src={iconLeft} alt="Icon" /></span> : ''}
            {child}
            {iconRight ? <span className="btn_icons_right"><img src={iconRight} alt="Icon" /></span> : ''}
        </Link>
}

export default Button