import chatIcon from '../../../assets/img/icons/chat_icon.svg'
import friendsIcon from '../../../assets/img/icons/friends_icon.svg'
import recordChatIcon from '../../../assets/img/icons/record_chat_icon.svg'
import cloudIcon from '../../../assets/img/icons/cloud_icon.svg'
import parrotChatImgUnactive from '../../../assets/img/bcg_parrot_subscription_unactive.png'
import Button from '../../../ui/Button'
import { PopupRegisterSubscription, PopupCongratulations } from '../../../components/Popups/Popups'
import { useState } from "react"
import Modal from 'react-modal'
import cross from '../../../assets/img/icons/cross-white.svg'
import { useTranslation } from 'react-i18next'

const SubscriptionUnctive = ({isPaidBefore}) => {
    const { t } = useTranslation()
    const [registerSubscriptionPopup, setRegisterSubscriptionPopup] = useState(false)
    Modal.setAppElement('#root')


    const openRegisterSubscriptionModal = (e) => {
        e.preventDefault()
        setRegisterSubscriptionPopup(true)
    }
    const closeRegisterSubscriptionModal = () => {
        setRegisterSubscriptionPopup(false)
    }

    return <div className='subscription-card unactive card-widget'>
        <div className='subscription-card__header'>
            <div className='flex'>
                <div className='subscription-card__header-heading'>
                    <span className='h2'>{t('subscriptions.period_subscription')}</span>
                    <span className='subtitle_big'><b className='h2'>{t('general.subscribe_price')}</b>/{t('subscriptions.period')}</span>
                </div>
                {isPaidBefore && <span className='btn-status h4 unactive'>{t('subscriptions.unactive')}</span>}
                
            </div>
            
            <Button tag="button" type="primary" onClick={openRegisterSubscriptionModal}>{isPaidBefore ? 'Restore subscription' : 'Subscribe now'}</Button>
            <Modal
                isOpen={registerSubscriptionPopup}
                onRequestClose={closeRegisterSubscriptionModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeRegisterSubscriptionModal}><img src={cross} alt="Close" /></div>
                    <PopupRegisterSubscription onCloseModal={closeRegisterSubscriptionModal} />
                </div>
            </Modal>
        </div>
        <div className='subscription-card__benefits'>
            <h3 className='subtitle_big subscription-card__subtitle'>{t('subscriptions.benefits_title')}</h3>
            <div className='flex-benefits'>
                <div className='flex-benefits__row'>
                    <img src={chatIcon} alt="chat icon" />
                    <span className='subtitle_big'>{t('subscriptions.individual_chats')}</span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={friendsIcon} alt="friends icon" />
                    <span className='subtitle_big'>{t('subscriptions.add_friends_unlimitedly_text')}</span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={recordChatIcon} alt="record chat icon" />
                    <span className='subtitle_big'>{t('subscriptions.record_chat_text')} </span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={cloudIcon} alt="cloud icon" />
                    <span className='subtitle_big'>{t('subscriptions.save_videos_cloud_text')}</span>
                </div>
            </div>
        </div>
        <div className='parrot-message unactive'>
            <div className='chat-message'>
                <p className='h4'>{t('subscriptions.insist_click_btn')} <br /> {t('subscriptions.insist_restore_subscription')} </p>
            </div>
            <img src={parrotChatImgUnactive} alt="parrot" />
        </div>
    </div>
}
export default SubscriptionUnctive