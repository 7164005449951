import Input from "../ui/Input";
import { useForm } from 'react-hook-form';
import Button from "../ui/Button";
import { apiConfig } from '../api/api.config.js';

const ResetPassword = () => {
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        console.log('click')
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const resetToken = urlParams.get('token');
            console.log(resetToken);
            const response = await fetch(apiConfig.url + '/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data, token: resetToken }), 
            });

            if (response.ok) {
                const result = await response.json();
                alert(result.message);
            } else {
                const error = await response.json();
                console.error('There was an error!', error);
                alert('Failed. Please try again.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            alert('Failed. Please try again.');
        }
    };

    return (
    <div className='content'>
        <div className="form-wrap">
            <div className="popup__change-email">
            <h2 className='popup__heading h2'>Recover your password</h2>
                    <span className='popup__description subtitle_medium'>Enter a new password and confirm it</span>
                    <form className='popup__form' onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            placeholder="Enter new password"
                            name="password"
                            type="password"
                            register={register}
                        />
                        <Input
                            placeholder="Confirm new password"
                            name="password_confirm"
                            type="password"
                            register={register}
                        />
                        <Button
                            tag="button" 
                            type="primary"
                        >Confirm</Button>
                    </form>
            </div>
        </div>
    </div>
    );
}

export default ResetPassword;
