import React, { useState } from 'react'

// const Input = React.forwardRef(({type, icon, size, className, wrapperClasses, register, errors, ...props}, ref) => {
const Input = ({type, icon, size, className, wrapperClasses, register, errors, validationSchema, name, passwordToCompare, ...props}) => {
    const [isPaswordShow, setPasswordShow] = useState(false)
    const wrapClass = wrapperClasses ? wrapperClasses : ''
    const inputClass = className ? className : ''
    const errorClass = errors && errors[name] ? 'error' : ''
    
    return <>
        {type === 'password' && name !== 'password_confirmation' ? 
        <div className={`input_div ${wrapClass && ''}`}>
            <input 
                className={`input input_password ${!isPaswordShow ? 'eyeInvisible' : ''} ${errors && errors[name] && 'error'} ${inputClass}`}
                type={isPaswordShow ? `text` : `password`} 
                {...register(name, validationSchema)}
                {...props}
            />
            <span className="icon_eye" onClick={() => setPasswordShow(!isPaswordShow)}></span>
            {errors && errors[name] && <span className='error'>{errors[name].message}</span>}
        </div> : name === 'password_confirmation' ? 
        <div className={`input_div ${wrapClass && ''}`}>
            <input 
                className={`input asd input_password ${!isPaswordShow ? 'eyeInvisible' : ''} ${errors && errors[name] && 'error'} ${inputClass}`}
                type={isPaswordShow ? `text` : `password`} 
                {...register("password_confirmation", {
                    validate: value =>
                        value === passwordToCompare || "The passwords do not match"
                })}
                {...props}
            />
            <span className="icon_eye" onClick={() => setPasswordShow(!isPaswordShow)}></span>
            {/* {errors[name] && <p>{errors[name].message}</p>} */}
            {errors?.password_confirmation && <span className='error'>{errors.password_confirmation.message}</span>}
        </div> :
        <div className={`input_div ${wrapClass && ''}`}>
            <input 
                className={`input text_medium ${errorClass} ${inputClass}`}
                type={type}
                {...register(name, validationSchema)}
                {...props}
            />
            {errors && errors[name]?.type === "required" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "pattern" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "minLength" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "maxLength" && (
                <span className="error">{errors[name]?.message}</span>
            )}
        </div>
        }

        {/* <input className="input input_icon" placeholder="Placeholder text" name="" type="text" /> */}
  
    </>
}

export default Input;
