import { useEffect, useState } from 'react'
import Pagi from '../../components/Pagi'
import FormLoader from '../../ui/FormLoader'
import FriendCard from './FriendCard'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const FriendsList = ({ status, friends }) => {
    const onlineSubstitle = "You can call your friends who are online"
    const offlineSubstitle = "You can't invite these friends to chat right now. Wait until your friends are online"
    const subtitle = status === 'online' ? onlineSubstitle : offlineSubstitle

    const [currentPage, setCurrentPage] = useState(1)
    const friendsLoading = useSelector(state => state.friends.isFriendsLoading)
    const pageCount = friends?.length
    const { t } = useTranslation()

    const handlePageChange = (e, pageIndex) => {
        e.preventDefault()
        setCurrentPage(pageIndex+1)
    }
    const onPrevPage = (e) => {
        e.preventDefault()
        setCurrentPage(currentPage-1)
    }
    const onNextPage = (e) => {
        e.preventDefault()
        setCurrentPage(currentPage+1)
    }

    useEffect(() => {
        if (currentPage > pageCount) {
            setCurrentPage(1)
        }
    }, [currentPage, pageCount])
    

    return <div className='status-friends-wrap'>
        {status === 'online' && <h2 className='h2 card-widget__title'>{t('friends.my_friends')}</h2>}
        {friendsLoading ? <FormLoader /> :
        <>
            <div className='status-friends-wrap__heading'>
                {status === 'online' ? <h2 className='h2 status online'>{t('friends.status_online')}</h2> 
                    : <h2 className='h2 status offline'>{t('friends.status_offline')}</h2>}
                <p className='subtitle_medium'>{subtitle}</p>
            </div>
            <div className='items-flex'>
                {friends && currentPage > 0 && Array.isArray(friends[currentPage - 1]) ? friends[currentPage-1].map(friend => {
                return <FriendCard data={friend} key={friend.id} status={status} />
                }) : <p>{t('friends.status_without_friends')} {status}</p>}
            </div>
            {friends?.length > 1 && 
            <Pagi 
                data={friends}
                handlePageChange={handlePageChange}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                currentPage={currentPage}
            />
            }
        </>
        }
    </div>
}

export default FriendsList