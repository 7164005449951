import VideoCard from '../../components/VideoCard'
import Pagi from '../../components/Pagi'
import { profileData } from '../../utils/Utils'
import VideoSubscribeCta from '../../components/VideoSubscribeCta'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSavedVideos } from '../../store/actions/saved-videos'
import { Link } from 'react-router-dom'
import prevIcon from '../../assets/img/icons/prev_arrow.svg'
import nextIcon from '../../assets/img/icons/next_arrow.svg'
import FormLoader from '../../ui/FormLoader'
import { useTranslation } from 'react-i18next'


const SavedVideosList = () => {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1)
    const videosList = useSelector(state => state.savedVideos.list)
    const videosLoading = useSelector(state => state.savedVideos.loading)
    const videosError = useSelector(state => state.savedVideos.error)
    const user = useSelector(state => state.auth.currentUser)
    console.log(user)
    const dispatch = useDispatch()

    const pageCount = videosList?.length

    const handlePageChange = (e, pageIndex) => {
        e.preventDefault()
        setCurrentPage(pageIndex+1)
    }
    const onPrevPage = (e) => {
        e.preventDefault()
        setCurrentPage(currentPage-1)
    }
    const onNextPage = (e) => {
        e.preventDefault()
        setCurrentPage(currentPage+1)
    }

    useEffect(() => {
        dispatch(getSavedVideos())
    }, [dispatch])

    useEffect(() => {
        if (currentPage > pageCount) {
            setCurrentPage(1)
        }
    }, [currentPage, pageCount])

    return <>
        {!user?.is_subscribed ? <VideoSubscribeCta /> : 
            videosLoading ? <FormLoader /> :
            videosList?.length && Array.isArray(videosList) ? <div className='card-widget card-widget__videos-card'>
            <h2 className='card-widget__title h2'>{t('videos.saved_videos')}</h2>
            <div className='videos-wrapper'>
                {videosList && currentPage > 0 && Array.isArray(videosList[currentPage - 1]) && 
                    videosList[currentPage - 1].map(video => (
                        <VideoCard data={video} key={video.id} />
                ))}
            </div>
            {videosList?.length > 1 && 
            <Pagi 
                data={videosList}
                handlePageChange={handlePageChange}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                currentPage={currentPage}
            />
            }
            {videosError?.message && <p className='error'>{videosError.message}</p>} 
        </div> : <div className='card-widget card-widget__empty'>
            <span className='subtitle_big'>{t('videos.question_have_videos')}</span>
        </div> }
    </>
}
export default SavedVideosList