import { useState } from "react"
import { Link } from "react-router-dom"
import Modal from 'react-modal'
import Button from "../../ui/Button"
import cross from '../../assets/img/icons/cross-white.svg'
import UserCard from '../../components/UserCard'
import { useDispatch } from "react-redux"
import { deleteFriend } from "../../store/actions/friends/friends"
import { useTranslation } from 'react-i18next'

const FriendCard = ({data, status}) => {
    const [unfollowFriendPopup, setUnfollowFriendPopup] = useState(false)
    const dispatch = useDispatch()
    Modal.setAppElement('#root')
    const { t } = useTranslation()

    const openUnfollowFriendModal = (e) => {
        e.preventDefault()
        setUnfollowFriendPopup(true)
    }
    const closeUnfollowFriendModal = () => {
        setUnfollowFriendPopup(false)
    }

    return <div className='users-item'>
        <UserCard 
            data={data} 
            openUnfollowFriendModal={openUnfollowFriendModal} 
            status={status}
        />
        <Modal
            isOpen={unfollowFriendPopup}
            onRequestClose={closeUnfollowFriendModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeUnfollowFriendModal}><img src={cross} alt="Close" /></div>
                <div className="popup__unfollow-friend">
                    <h2 className='popup__heading h2'>{t('friends.unfollow_friend')}</h2>
                    <span className='popup__unfollow-friend_descr subtitle_big'>{t('friends.want_remove')}<Link className='h3' to="/" onClick={(e) => e.preventDefault()}>{data.name}</Link> {t('friends.from_friends')}</span>
                    <div className='btns-flex'>
                        <Button onClick={closeUnfollowFriendModal}
                            tag="button"
                            type="secondary" 
                        >{t('general.cancel')}</Button>
                        <Button tag="button"
                            type="primary" 
                            onClick={() => dispatch(deleteFriend(data.id))}
                        >{t('general.configrm_button_text')}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}


export default FriendCard