import React from "react";
import { useSelector } from 'react-redux';
import Layout from "./Layout";
import { Navigate } from "react-router";

const LayoutAuth = ({ component: Component }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to='/' />;
  }

  return <Layout className="layout_auth" isLoggedIn={isLoggedIn}><Component /></Layout>;
}

export default React.memo(LayoutAuth);