import { useForm } from 'react-hook-form'
import Input from "../../ui/Input"
import Button from "../../ui/Button"
import Select, { components } from 'react-select'
import {selectClassNames, isObjEmpty, genderOptions} from '../../utils/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from '../../store/actions/profile'
import FormLoader from '../../ui/FormLoader'
import { useEffect, useState } from 'react'
import icon_gender from "../../assets/img/icons/gender.svg"
import { useTranslation } from 'react-i18next'
import {useCookies} from 'react-cookie'
import icon_gender_dark from '../../assets/img/icons/gender_dark.svg' 

const ProfileInfoForm = () => {
    const currentUser = useSelector(state => state.profile.currentUser)
    const [ownerName, setName] = useState(currentUser?.name ? currentUser.name : '')
    const [petName, setPetName] = useState(currentUser?.pet_name ? currentUser.pet_name : '')
    const [petBreed, setPetBreed] = useState()
    const [petGender, setPetGender] = useState()
    const [isDataUpdated, setDataUpdated] = useState(false)
    const profileLoading = useSelector(state => state.profile.profileLoading)
    const profileError = useSelector(state => state.profile.profileError)
    const breedsList = useSelector(state => state.breeds.breedsList)
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['theme', 'language'])
    
    const dispatch = useDispatch()
    
    useEffect(() => {
        currentUser?.name && setName(currentUser?.name)
        currentUser?.pet_name && setPetName(currentUser?.pet_name)
        currentUser?.pet_name && setValue('pet_name', currentUser.pet_name)
        currentUser?.name && setValue('name', currentUser.name)
    }, [currentUser])
    
    useEffect(() => {
        breedsList && breedsList.forEach((b) => {
            if(b.id === +currentUser?.pet_breed) {
                setPetBreed(b)
            }
        })
    }, [breedsList, currentUser, setPetBreed])

    useEffect(() => {
        genderOptions && genderOptions.forEach((g) => {
            if(g.value === currentUser?.pet_gender) {
                setPetGender(g)
            }
        })
    }, [currentUser, setPetGender])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()
    
    const onSubmit = (data) => {
        const formData = data
        formData.pet_breed = petBreed.id
        formData.pet_gender = petGender?.value
        
        if (isObjEmpty(errors)) {
            dispatch(updateProfile(formData)).then(response => {
                console.log("Профіль успішно оновлено!", response);
                setDataUpdated(true)
                setTimeout(() => {
                    setDataUpdated(false)
                }, 5000)
            }).catch(error => {
                console.error("Профіль оновлення емейлу:", error)
            })
        }
    }
    const onCancelChanges = (e) => {
        e.preventDefault()
        setValue('pet_name', currentUser?.pet_name)
        setValue('name', currentUser?.name)
        breedsList?.forEach((b) => {
            if(b.id === +currentUser?.pet_breed) {
                setPetBreed(b)
            }
        })
        genderOptions && genderOptions.forEach((g) => {
            if(g.value === currentUser?.pet_gender) {
                setPetGender(g)
            }
        })
    }

    const { Option } = components
    const IconOption = props => (
        <Option {...props}>
            <span className="flex justify-between">
                {props.data.label}
                <img
                    src={props.data.icon}
                    style={{ width: 24 }}
                    alt={props.data.label}
                />
            </span>
        </Option>
    )
    const IconPlaceholder = props => (
        <span className="flex mt-[-22px]">
            <span className="mr-[10px]">{props.children}</span>
            <img
                src={cookies?.theme === 'dark' ? icon_gender : icon_gender_dark}
                style={{ width: 24 }}
                alt="Gender"
            />
        </span>
    )

    return <>
        {profileLoading ? <FormLoader /> : 
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type="text" 
                placeholder="Pet name"
                name="pet_name"
                errors={errors}
                register={register}
                defaultValue={petName}
                onChange={(e) => setPetName(e.target.value)}
                validationSchema={{ 
                    required: "Pet name is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    }
                }}
            />
            <Input 
                type="text" 
                placeholder="Owner’s name" 
                name="name"
                errors={errors}
                register={register}
                defaultValue={ownerName}
                onChange={(e) => setName(e.target.value)}
                validationSchema={{ 
                    required: "Name is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    }
                }}
            />
            {breedsList?.length && <Select 
                options={breedsList} 
                onChange={data => setPetBreed(data)}
                value={petBreed}
                defaultValue = {
                    breedsList.filter(b => b.id === currentUser?.pet_breed)
                }
                className="w-full select-container"
                placeholder="Pet breed"
                isSearchable={false}
                classNames={selectClassNames}
                components={{
                    IndicatorSeparator: () => null
                }}
                getOptionLabel={(breedOption) => breedOption.name}
                getOptionValue={(breedOption) => breedOption.id} 
            /> }
            {currentUser && <Select 
                options={genderOptions} 
                className="w-full select-container"
                placeholder="Pet gender"
                isSearchable={false}
                classNames={selectClassNames}
                components={{
                    IndicatorSeparator: () => null,
                    Option: IconOption,
                    Placeholder: IconPlaceholder
                }}
                onChange={data => setPetGender(data)}
                value={petGender}
                defaultValue = {
                    genderOptions.filter(g => g.value === currentUser?.pet_gender)
                }
            />
            }
            <div className='flex-btns'>
                {/* <Button tag="button" type="secondary" onClick={onReset}>Cancel changes</Button> */}
                <Button tag="button" type="secondary" onClick={onCancelChanges}>{t('profile.cancel_changes')}</Button>
                <Button tag="button">{t('profile.save')} </Button>
            </div>
            {profileError?.response?.status === 401 ? <p className='form__error'>{t('general.incorrect_data_text')}</p> : profileError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
            {isDataUpdated && <p className='success text-left w-full pl-[13px] mt-2'>{t('general.profile_update_successfully')}</p>}
        </form>
        }
    </>
}

export default ProfileInfoForm