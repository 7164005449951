import { Link } from 'react-router-dom'
import { useState, forwardRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Button from "../../ui/Button"
import Input from "../../ui/Input"
import parrotLogout from '../../assets/img/icons/parrot_popup_logout.svg'
import newPhotoProfile from '../../assets/img/parrot_new_fotoprofile.png'
import iconSecure from '../../assets/img/icons/IconsSecure.svg'
import iconWarning from '../../assets/img/icons/IconsWarnings.svg'
import chatIcon from '../../assets/img/icons/chat_icon.svg'
import friendsIcon from '../../assets/img/icons/friends_icon.svg'
import recordChatIcon from '../../assets/img/icons/record_chat_icon.svg'
import cloudIcon from '../../assets/img/icons/cloud_icon.svg'
import TextArea from '../../ui/TextArea'
import Select from 'react-select'
import {selectClassNames} from '../../utils/Utils'
import { apiConfig } from '../../api/api.config.js'
import MaskedInput from 'react-text-mask'

const MaskedTextInput = forwardRef((props, ref) => (
    <MaskedInput {...props} ref={(input) => {
      if (input) {
        ref(input.inputElement)
      }
    }} />
))

export const PopupLogout = ({onCloseModal, onUserLogout}) => {
    return (
        <div className="popup__logout">
            <h2 className='popup__heading h2'>Log out of the profile</h2>
            <span className='popup__logout-subheading h4'>Are you want to logout?</span>
            <div className='popup__icon-parrot'>
                <img src={parrotLogout} alt="parrot" />
            </div>
            <div className="btns-flex">
                <Button tag="button"
                    type="secondary"
                    onClick={onCloseModal} 
                >Cancel</Button>
                <Button 
                    tag="button"
                    type="primary" 
                    onClick={onUserLogout}
                >I'm sure</Button>
            </div>
        </div>
    )
}

export const PopupProfilePhoto = ({onCloseModal}) => {
    const [data, setData]=useState(0)
    const [dataSecond, setDataSecond]=useState(0)
    return (
        <div className="profile-photo">
            <h2 className='profile-photo__heading h2'>New profile photo</h2>
            <span className='profile-photo__description subtitle_medium'>SVG, PNG, JPG (max. 800x400px)</span>
            <div className='change-photo-profile'>
                <img src={newPhotoProfile} alt="new profile" />
            </div>
            <div className='photo-ranges'>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>Size</span>
                    <div className="photo-range__input">
                        <input type="range" min="0" max="50" value={data} id="range1" onChange={(e)=>setData(e.target.value)}/> 
                    </div>
                </div>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>Angular</span>
                    <div className="photo-range__input">
                        <input type="range" min="0" max="50" value={dataSecond} id="range2" onChange={(e)=>setDataSecond(e.target.value)}/> 
                    </div>
                </div>
            </div>
            <div className="btns-flex">
                <Button to="/"
                    onClick={onCloseModal}
                    tag="button"
                    type="secondary" 
                >Cancel</Button>
                <Button to="/"
                    tag="button"
                    type="primary" 
                >Save</Button>
            </div>
        </div>
    )
}

export const PopupRegisterSubscription = ({ onCloseModal }) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    const validateCardNumber = value => {
        const number = value.replace(/\s|_/g, '')
        return number.length === 16 || 'Card number is incomplete'
    }
    const validateExpiryDate = value => {
        const [month, year] = value.split('/').map(part => parseInt(part, 10));
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        if (month < 1 || month > 12) {
          return 'Invalid month';
        }
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
          return 'Card has expired';
        }
        return true;
    }

    const validateCVV = value => {
        const cvv = value.replace(/_/g, ''); // Видаляємо символи '_'
        if (cvv.length === 0) {
          return 'CVV is required';
        }
        return (cvv.length === 3 || cvv.length === 4) || 'CVV must be 3 digits';
    }

    const onSubmit = async (data) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(apiConfig.url+'/subscribe', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: 5, 
                    currency: 'USD', 
                    card: data.card_number.replace(/\s/g, ''),
                    card_exp_month: data.year_card.split('/')[0],
                    card_exp_year: data.year_card.split('/')[1],
                    card_cvv: data.cvv_card,
                }),
            });

            if (response.ok) {
                const result = await response.json();
                onCloseModal();
                window.location.reload();
            } else {
                const error = await response.json();
                console.error('There was an error!', error);
                alert('Subscription failed. Please try again.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            alert('Subscription failed. Please try again.');
        }
    };

    return (
        <div className="popup__register-subscription">
            <h2 className='popup__heading h2'>Registration of <br /> subscription</h2>
            <h3 className='h3'>The subscription fee is <span>$5 / <b className='subtitle_medium'>month</b></span></h3>
            <form onSubmit={handleSubmit(onSubmit)} className='popup__form popup__form-card'>
            <Controller
                name="card_number"
                control={control}
                rules={{ 
                    required: 'Card number is required', 
                    validate: validateCardNumber
                }}
                render={({ field }) => (
                    <div className='input_div'>
                        <MaskedTextInput
                            {...field}
                            mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                            placeholder="Enter your card number"
                            type="text"
                            className="input text_medium"
                        />
                        {errors && errors.card_number && <span className="error">{errors.card_number.message}</span>}
                    </div>
                    )}
                />
                <div className='popup__inputs-flex'>
                    <Controller
                        name="year_card"
                        control={control}
                        rules={{ 
                            required: 'Card number is required',
                            validate: validateExpiryDate
                        }}
                        render={({ field }) => (
                            <div className='input_div'>
                                <MaskedTextInput
                                    {...field}
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                                    placeholder="MM/YY"
                                    type="text"
                                    className="input text_medium"
                                />
                                {errors && errors.year_card && <span className="error">{errors.year_card.message}</span>}
                            </div>
                        )}
                    />
                    <Controller
                        name="cvv_card"
                        control={control}
                        rules={{ 
                            required: 'Card number is required',
                            validate: validateCVV
                        }}
                        render={({ field }) => (
                            <div className='input_div'>
                                <MaskedTextInput
                                    {...field}
                                    mask={[/\d/, /\d/, /\d/]}
                                    placeholder="CVV"
                                    type="text"
                                    className="input text_medium"
                                />
                                {errors && errors.cvv_card && <span className="error">{errors.cvv_card.message}</span>}
                            </div>
                        )}
                    />
                </div>
                <Button
                    tag="button" 
                    type="primary"
                >Checkout</Button>
                <Button
                    onClick={onCloseModal}
                    tag="button" 
                    type="secondary"
                >Cancel</Button>
                <div className='popup__descr-icon'>
                    <img src={iconWarning} alt="warning" />
                    <span className='subtitle_medium'>Money for the subscription is automatically withdrawn every month</span>
                </div>
                <div className='popup__descr-icon popup__descr-icon_green'>
                    <img src={iconSecure} alt="secure" />
                    <span className='subtitle_medium'>Secure payment</span>
                </div>
            </form>
        </div>
    );
};

export const PopupCongratulations = ({onCloseModal}) => {
    return (
        <div className="popup__congratulations">
            <h2 className='popup__heading h2'>Congratulations!</h2>
            <div className='popup__icon-parrot'>
                <img src={parrotLogout} alt="parrot" />
            </div>
            <span className='popup__description subtitle_medium'>You have successfully subscribed! Thank you for being with us. You can manage your subscriptions in your personal profile</span>
            <Button to="/"
                    onClick={onCloseModal}
                    tag="button"
                    type="primary" 
            >Understood</Button>
        </div>
    )
}

export const PopupCancelSuscription = ({onCloseModal}) => {
    const onSubmit = async (data) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(apiConfig.url+'/unsubscribe', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                onCloseModal();
                window.location.reload();
            } else {
                const error = await response.json();
                console.error('There was an error!', error);
                alert('Unsubscription failed. Please try again.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            alert('Unsubscription failed. Please try again.');
        }
    };
    return (
        <div className="popup__cancel-subscription">
            <h2 className='popup__heading h2'>Cancel your subscription</h2>
            <p className='popup__description subtitle_medium'> 
            <span className='h4'>Are you sure you want to <b>сancel</b> your subscription?</span> <br />
            By unsubscribing, you will lose these benefits:</p>
            <div className='benefits-col'>
                <div className='benefits-col__row'>
                    <img src={chatIcon} alt="chat" />
                    <span className='subtitle_big'>Access to individual chats</span>
                </div>
                <div className='benefits-col__row'>
                    <img src={friendsIcon} alt="friends" />
                    <span className='subtitle_big'>Ability to add friends unlimitedly</span>
                </div>
                <div className='benefits-col__row'>
                    <img src={recordChatIcon} alt="recird chat" />
                    <span className='subtitle_big'>Ability to record chat </span>
                </div>
                <div className='benefits-col__row'>
                    <img src={cloudIcon} alt="cloud" />
                    <span className='subtitle_big'>Ability to save videos in the cloud</span>
                </div>
            </div>
            <div className="btns-flex">
                <Button to="/"
                    onClick={onCloseModal}
                    tag="button"
                    type="secondary" 
                >Don't unsubscribe</Button>
                <Button onClick={onSubmit} to="/"
                    tag="button"
                    type="primary" 
                >I'm sure</Button>
            </div>
        </div>
    )
}

export const PopupChangePassword = () => {
    const {
        register,
    } = useForm()
    return (
        <div className="popup__change-password">
            <h2 className='popup__heading h2'>Change your password</h2>
            <span className='popup__description subtitle_medium'>To change your password, first enter the old password, then enter the new password and confirm it</span>
            <form action="" className='popup__form'>
                <Input
                    placeholder="Enter password"
                    name="password"
                    type="password"
                    register={register}
                />
                <Input
                    placeholder="Enter new password"
                    name="password_new"
                    type="password"
                    register={register}
                />
                <Input
                    placeholder="Confirm new password"
                    name="pasword_new_confirm"
                    type="password"
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >confirm</Button>
                <div className='question'>
                    <span className='subtitle_medium'>Forgot your password? <Link className='question__link subtitle_big subtitle_big_underline' to="/">Recover</Link></span>
                </div>
            </form>
        </div>
    )
}

export const PopupReplaceCard = ({onCloseModal}) => {
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(apiConfig.url+'/change-card', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: 5, 
                    currency: 'USD', 
                    card: data.card_number.replace(/\s/g, ''),
                    card_exp_month: data.year_card.split('/')[0],
                    card_exp_year: data.year_card.split('/')[1],
                    card_cvv: data.cvv_card,
                }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result)
                onCloseModal();
                // window.location.reload();
                reset();
            } else {
                const error = await response.json();
                console.error('There was an error!', error);
                alert('Subscription failed. Please try again.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            alert('Subscription failed. Please try again.');
        }
    }
    const validateCardNumber = value => {
        const number = value.replace(/\s|_/g, '')
        return number.length === 16 || 'Card number is incomplete'
    }
    const validateExpiryDate = value => {
        const [month, year] = value.split('/').map(part => parseInt(part, 10));
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        if (month < 1 || month > 12) {
          return 'Invalid month';
        }
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
          return 'Card has expired';
        }
        return true;
    }

    const validateCVV = value => {
        const cvv = value.replace(/_/g, ''); // Видаляємо символи '_'
        if (cvv.length === 0) {
          return 'CVV is required';
        }
        return (cvv.length === 3 || cvv.length === 4) || 'CVV must be 3 digits';
    }

    return (
        <div className="popup__replace-card">
            <h2 className='popup__heading h2'>Replace your card</h2>
            <span className='popup__description subtitle_big'>To replace the card, you need to enter the payment information of the new card. After replacement, the old card will be deleted automatically</span>
            <form onSubmit={handleSubmit(onSubmit)} className='popup__form popup__form-card'>
                <Controller
                    name="card_number"
                    control={control}
                    rules={{ 
                        required: 'Card number is required', 
                        validate: validateCardNumber
                    }}
                    render={({ field }) => (
                        <div className='input_div'>
                            <MaskedTextInput
                                {...field}
                                mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Enter your card number"
                                type="text"
                                className="input text_medium"
                            />
                            {errors && errors.card_number && <span className="error">{errors.card_number.message}</span>}
                        </div>
                      )}
                />
                <div className='popup__inputs-flex'>
                    <Controller
                        name="year_card"
                        control={control}
                        rules={{ 
                            required: 'Card number is required',
                            validate: validateExpiryDate
                        }}
                        render={({ field }) => (
                            <div className='input_div'>
                                <MaskedTextInput
                                    {...field}
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                                    placeholder="MM/YY"
                                    type="text"
                                    className="input text_medium"
                                />
                                {errors && errors.year_card && <span className="error">{errors.year_card.message}</span>}
                            </div>
                        )}
                    />
                    <Controller
                        name="cvv_card"
                        control={control}
                        rules={{ 
                            required: 'Card number is required',
                            validate: validateCVV
                        }}
                        render={({ field }) => (
                            <div className='input_div'>
                                <MaskedTextInput
                                    {...field}
                                    mask={[/\d/, /\d/, /\d/]}
                                    placeholder="CVV"
                                    type="text"
                                    className="input text_medium"
                                />
                                {errors && errors.cvv_card && <span className="error">{errors.cvv_card.message}</span>}
                            </div>
                        )}
                    />
                </div>
                <Button to="/"
                        tag="button" 
                        type="primary" 
                >replace</Button>
                <Button to="/"
                        onClick={onCloseModal}
                        tag="button" 
                        type="secondary" 
                >Cancel</Button>
                <div className='popup__descr-icon'>
                    <img src={iconWarning} alt="warning" />
                    <span className='subtitle_medium'>Money for the subscription is automatically withdrawn every month</span>
                </div>
                <div className='popup__descr-icon popup__descr-icon_green'>
                    <img src={iconSecure} alt="secure" />
                    <span className='subtitle_medium'>Secure payment</span>
                </div>
            </form>
        </div>
    )
}

export const PopupRecoverPassword = () => {
    const {
        register,
    } = useForm()
    return (
        <div className="popup__recover-password">
            <h2 className='popup__heading h2'>Recover your password</h2>
            <span className='popup__description subtitle_big'>Enter a new password and confirm it</span>
            <form action="" className='popup__form'>
                <Input
                    placeholder="Enter password"
                    name="card_number"
                    type="password"
                    register={register}
                />
                <Input
                    placeholder="Enter new password"
                    name="card_number"
                    type="password"
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >Confirm change</Button>
            </form>
        </div>
    )
}

export const PopupVerifyNewPassword = () => {
    const {
        register,
    } = useForm()
    return (
        <div className="popup__verify-password">
            <h2 className='popup__heading h2'>Verify your new E-mail</h2>
            <span className='popup__description subtitle_big'>We have sent a verification code to the specified mail, enter it in the field below</span>
            <form action="" className='popup__form'>
                <Input
                    placeholder="Enter code"
                    name="card_number"
                    type="text"
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >Confirm</Button>
                <span className='popup__verify-password_resend-request subtitle_big'>If you have not received the code, you can <br /> <Link to="/">resend the request</Link> in 2m</span>
            </form>
        </div>
    )
}

export const PopupDeletePhoto = ({onCloseModal}) => {
    return (
        <div className="popup__delete-photo">
            <h2 className='popup__heading h2'>Delete a photo</h2>
            <span className='popup__delete-photo_descr subtitle_big'>Are you sure you want to delete your profile photo?</span>
            <div className='btns-flex'>
                <Button to="/"
                    onClick={onCloseModal}
                    tag="button"
                    type="secondary" 
                >Cancel</Button>
                <Button to="/"
                    tag="button"
                    type="primary" 
                >I'm sure</Button>
            </div>
        </div>
    )
}

export const PopupChangeEmail = () => {
    const {
        register,
    } = useForm()
    return (
        <div className="popup__change-email">
            <h2 className='popup__heading h2'>Change your E-mail</h2>
            <span className='popup__description subtitle_big'>To change your email address, write a new address and confirm it</span>
            <form action="" className='popup__form'>
                <Input
                    placeholder="Enter new E-mail"
                    name="new_email"
                    type="text"
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >confirm</Button>
            </form>
        </div>
    )
}

export const PopupUnfollowFriend = ({onCloseModal, deleteFriend, id}) => {
    return (
        <div className="popup__unfollow-friend">
            <h2 className='popup__heading h2'>Unfollow a friend</h2>
            <span className='popup__unfollow-friend_descr subtitle_big'>Are you sure you want to remove <Link className='h3' to="/">Nicknameofmyfriend115</Link> from your friends?</span>
            <div className='btns-flex'>
                <Button onClick={onCloseModal}
                    tag="button"
                    type="secondary" 
                >Cancel</Button>
                <Button tag="button"
                    type="primary" 
                    onClick={() => deleteFriend(id)}
                >I'm sure</Button>
            </div>
        </div>
    )
}

export const PopupConfirmationCode = () => {
    const {
        register,
    } = useForm()
    return (
        <div className="popup__confirmation-code">
            <h2 className='popup__heading h2'>Recover your password</h2>
            <span className='popup__description subtitle_big'>We have sent you a confirmation code to change your password. If you don't want to reset your password, please ignore this email</span>
            <form action="" className='popup__form'>
                <Input
                    placeholder="Enter code"
                    name="new_email"
                    type="text"
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >confirm</Button>
            </form>
        </div>
    )
}

export const PopupUploadPhoto = ({onCloseModal}) => {
    const [dataThird, setData] = useState(false)
    const [dataFour, setDataFour] = useState(false)
    return (
        <div className="upload-step">
            <h2 className='popup__heading h2'>Last step</h2>
            <span className='popup__description subtitle_medium'>Upload your pet's image now, or you can do it later</span>
            <div className='change-photo-profile'>
                <div className='change-photo-profile__text-part'>
                    <Link to="/" className='text_small'><p className='subtitle_medium'>Click to upload</p> <span>or drug and drop</span> <br /> SVG, PNG, JPG (max. 800x400px)</Link>
                </div>
            </div>
            <div className='photo-ranges'>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>Size</span>
                    <div className="photo-range__input">
                        <input type="range" min="0" max="50" value={dataThird} id="range3" onChange={(e)=>setData(e.target.value)} disabled={!dataThird} /> 
                    </div>
                </div>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>Angular</span>
                    <div className="photo-range__input">
                        <input type="range" min="0" max="50" id="range2" value={dataFour} onChange={(e)=>setDataFour(e.target.value)}  disabled={!dataFour}/> 
                    </div>
                </div>
            </div>
            <Button to="/"
                    onClick={onCloseModal}
                    tag="button"
                    type="secondary" 
            >Do it later</Button>
        </div>
    )
}

export const PopupReportVideo = () => {
    const [reportTopic, setReportTopic] = useState()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const reportTopicsOptions = [
        {value: 1, label: 'Animal cruelty'},
        {value: 2, label: 'Bad behavior'},
    ]

    const onSubmit = (data) => {
        console.log (data);
        reset();
    }

    return (
        <div className="popup__report-video">
            <h2 className='popup__heading h2'>Report a violation</h2>
            <form onSubmit={handleSubmit(onSubmit)} className='popup__form'>
                <Select
                    options={reportTopicsOptions} 
                    name="report_topics"
                    className="w-full select-container"
                    placeholder="Choose the topic of your appeal"
                    isSearchable={false}
                    classNames={selectClassNames}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setReportTopic(data)}
                    value={reportTopic}
                />
                <TextArea
                    placeholder="Please describe the problem in more detail..."
                    name="report_details"
                    register={register}
                    errors={errors}
                    validationSchema={{ 
                        required: "This field is required",
                        minLength: {
                            value: 3,
                            message: "Please, use more symbols"
                        },
                        maxLength: {
                            value: 1000,
                            message: "Please, use less symbols"
                        }
                    }}
                />
                <Button tag="button" 
                    type="primary" 
                >Send</Button>
            </form>
        </div>
    )
}