import notification from '../../assets/img/icons/bell.svg'
import cross from '../../assets/img/icons/cross-white.svg'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acceptFriendRequest, getFriendsRequests } from '../../store/actions/friends/friends'
import FormLoader from '../../ui/FormLoader'
import { DefaultAvatar, storageUrl } from '../../utils/Utils'
import addfriendIcon from '../../assets/img/icons/add_friends.svg'
import Button from '../../ui/Button'
import { echoLaravel } from '../../utils/Utils'
import gender_boy from "../../assets/img/icons/gender-boy.svg"
import gender_boy_dark from "../../assets/img/icons/gender-boy-dark.svg"
import gender_girl from "../../assets/img/icons/gender-girl.svg"

import { useNavigate } from "react-router-dom"
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const NavbarNotifications = ({notificationPopup, toggleNotifications, notificationMenu, sendDataToParent, sendInvitesToParent}) => {

    const friendsRequests = useSelector(state => state.friends.friendsRequests)
    const isFriendsLoading = useSelector(state => state.friends.isFriendsLoading)
    const currentUser = useSelector(state => state.auth.currentUser)
    const dispatch = useDispatch()
    const [cookies] = useCookies(['theme'])
    const { t } = useTranslation()

    const navigate = useNavigate()
    const [invitesList, setInvitesList] = useState([])

    useEffect(() => {
        if (currentUser) {
            echoLaravel.join('friends-chanel')
            .listen('FriendsChanel', async (event) => {
                if (currentUser && event?.data?.type === 'sendFriendRequest' && +event.data.recipientId === currentUser.id) {
                    dispatch(getFriendsRequests())
                }
                if (currentUser && event?.data?.type === 'incomingCall' && +event.data.userToCall === currentUser.id) {
                    let inviteMatch = false
                    invitesList.forEach(e => {
                        if(event.data.from === e.from) inviteMatch = true
                    })
                    if(!inviteMatch) setInvitesList([...invitesList, event.data])
                }
            })
            .subscribed(() => {
                console.log('Connected to channel FriendsChanel');
            })
            .error((error) => {
                console.error('Channel error:', error);
            })
            return () => echoLaravel.leave('friends-chanel')
        }
    }, [dispatch, currentUser])

    useEffect(() => {
        dispatch(getFriendsRequests())
    }, [dispatch])

    useEffect(() => {
        sendDataToParent(friendsRequests)
    }, [sendDataToParent, friendsRequests])

    useEffect(() => {
        sendInvitesToParent(invitesList)
    }, [sendInvitesToParent, invitesList])

    const onAcceptFriend = (id) => {
        dispatch(acceptFriendRequest(id))
    }

    const acceptCall = data => {
        return navigate('/friend-call?incomingCallFrom='+data);
    };

    

    return isFriendsLoading ? <FormLoader /> :
    <div className={`notification__popup ${notificationPopup ? 'active' : ''}`} ref={notificationMenu}>
        <div className="popup__head">
            <div className='flex items-center'>
                <div 
                    className={`icon-wrap ${friendsRequests?.length ? 'unread' : ''}`} 
                ><img src={notification} alt="Notification" /></div>
                <p className='h3'>{t('general.navbar_unread_messages')}</p>
            </div>
            <button 
                onClick={toggleNotifications}
            ><img src={cross} alt="Close" /></button>
        </div>
        {friendsRequests?.length || invitesList?.length ? (
        <div className="notification__list">
            {invitesList?.length ? invitesList.map((n) => (
            <div className="notification__item" key={n.from}>
                <div className='flex items-center'>
                    <div className="img-wrap">
                        {n.image ? <img src={`${storageUrl}/${n.image}`} alt={n.fromName} /> : <DefaultAvatar alt={n.fromName} />}
                    </div>
                    <div className="text">
                        <div className="flex items-center">
                            <p className='h4 mr-[10px]'>{n.petName}</p>
                            {n.petGender && <img src={n.petGender === 'boy' ? cookies?.theme === 'dark' ? gender_boy : gender_boy_dark : gender_girl} alt="Gender" className="mr-[10px]" />}
                            <p className='subtitle_medium'>{n.petBreedName}</p>
                        </div>
                        <p className='h4'>{n.fromName}</p>
                    </div>
                </div>
                <Button tag="button" className='btn_primary btn' onClick={() => acceptCall(n.from)}>{t('general.navbar_join_chat_btn')}</Button>
            </div>
            )) : ''}
            {friendsRequests?.length ? friendsRequests.map((n) => (
            <div className="notification__item" key={n.id}>
                <div className='flex items-center'>
                    <div className="img-wrap">
                        {n.image ? <img src={`${storageUrl}/${n.image}`} alt={n.name} /> : <DefaultAvatar alt={n.name} />}
                    </div>
                    <div className="text">
                        <div className="flex items-center">
                            <p className='h4 mr-[10px]'>{n.pet_name}</p>
                            {n.pet_gender && <img src={n.pet_gender === 'boy' ? cookies?.theme === 'dark' ? gender_boy : gender_boy_dark : gender_girl} alt="Gender" className="mr-[10px]" />}
                            <p className='subtitle_medium'>{n.breed?.name}</p>
                        </div>
                        <p className='h4'>{n.name}</p>
                    </div>
                </div>
                <Button tag="button" className='add_friend btn' onClick={() => onAcceptFriend(n.id)}>
                    <span className='h4'>{t('general.navbar_add_friend_btn')}</span>
                    <img src={addfriendIcon} alt="Add friend" />
                </Button>
            </div>
            )) : ''}
        </div>
        ) : (
            <div className='notification_empty'>
                <p className='subtitle_big'>{t('general.navbar_no_invitation_to_chat')}</p>
            </div>
        )}     
    </div>

    
}

export default NavbarNotifications