import { Link } from 'react-router-dom'
import logo from '../../assets/img/full_logo.svg'
import logo_dark from '../../assets/img/full_logo-dark.svg'
import logo_mobile from '../../assets/img/parrot_icon.svg'
import NavbarBreeds from './NavbarBreeds'
import NavbarElements from './NavbarElements'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

const Navbar = () => {
    const user = useSelector(state => state.auth.currentUser)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isSubscribed = isLoggedIn && user?.is_subscribed
    const [cookies] = useCookies(['theme'])
    // const isAuthLoading = useSelector(state => state.auth.isAuthLoading)
    
    return <div className='navbar'>
        <div className={`container flex ${isLoggedIn ? 'justify-between' : 'justify-center'} items-center h-full`}>
            {isLoggedIn ? <NavbarBreeds isSubscribed={isSubscribed} /> : null}
            <Link to="/" className='logo-wrap'>
                <img src={cookies?.theme === 'dark' ? logo : logo_dark} className='mobile-hidden' alt="Parrot" />
                <img src={logo_mobile} className='desktop-hidden' alt="Parrot" />
            </Link>
            {isLoggedIn ? <NavbarElements user={user} /> : null}
        </div>
    </div>
}

export default Navbar