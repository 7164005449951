import { Link, useNavigate } from "react-router-dom"
import Button from "../ui/Button"
import userImage from '../assets/img/icons/avatar-example.png'
import { storageUrl } from "../utils/Utils"
import gender_boy from "../assets/img/icons/gender-boy.svg"
import gender_girl from "../assets/img/icons/gender-girl.svg"
import { useTranslation } from 'react-i18next'


const UserCard = ({data, openUnfollowFriendModal, status}) => {
    const navigate = useNavigate()

    const inviteFriend = data => {
        return navigate('/friend-call?userId='+data);
    };

    const { t } = useTranslation()
    
    return <>
        <div className='users-item_left'>
            <div className='users-item__img'>
                {data.image ? <img src={`${storageUrl}/${data.image}`} alt={data.name} /> : <img src={userImage} alt={data.name} />}
            </div>
            <div className='users-item__text'>
                <div className="flex items-center">
                    <h4 className='name h4 mr-[10px]'>{data.pet_name}</h4>
                    {data.pet_gender && <img src={data.pet_gender === 'boy' ? gender_boy : gender_girl} alt="Gender" className="mr-[10px]" />}
                    <p className="subtitle_medium type">{data.breed?.name}</p>
                </div>
                <span className='h4'>{data.name}</span>
            </div>
        </div>
        <div className='users-item__btns'>
            {status === 'online' && (
                <Button  onClick={() => inviteFriend(data.id)} tag="button" type="primary" >
                    {t('home.invite')} <b>{t('home.chat')}</b>
                </Button>
            )}
            <Link to="/" className='delete_btn' onClick={openUnfollowFriendModal}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 4C9.44772 4 9 4.44772 9 5H15C15 4.44772 14.5523 4 14 4H10ZM17 7H7V17C7 18.6569 8.34315 20 10 20H14C15.6569 20 17 18.6569 17 17V7ZM5 7V17C5 19.7614 7.23858 22 10 22H14C16.7614 22 19 19.7614 19 17V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17C17 3.34315 15.6569 2 14 2H10C8.34315 2 7 3.34315 7 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#94A8B4"/>
                </svg>
                <span>{t('general.remove')}</span>
            </Link>
        </div>
    </>
}
export default UserCard