import actionTypes from '../action-types/savedVideosTypes';
const initialState = {
    loading: false,
    list: null,
    error: null
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.SAVED_VIDEOS_INFO) {
        return {
            ...state,
            list: action.data,
        };
    }

    if (action.type === actionTypes.SAVED_VIDEOS_LOADING) {
        return {
            ...state,
            loading: action.loading
        };
    }
    if (action.type === actionTypes.SAVED_VIDEOS_ERROR) {
        return {
            ...state,
            error: action.error
        };
    }
    return { ...state };
};

export default reducer;