import SignupUploadPhoto from '../modules/auth/SignupUploadPhoto'

const SignupPhoto = () =>{
    return <>
        <div className='content'>
                <SignupUploadPhoto />
        </div>
    </>
}

export default SignupPhoto