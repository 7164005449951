import VerifyForm from '../modules/auth/VerifyForm'

const Verify = () =>{
    return <>
        <div className='content'>
            <VerifyForm />
        </div>
    </>
}

export default Verify;