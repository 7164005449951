import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import store from './store/store'
import { router } from './routes/routes'
import { useTranslation, I18nextProvider } from 'react-i18next'
import i18n, { changeLanguage } from './utils/i18n' 
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

const App = () => {
  const { t } = useTranslation()
  const [cookies] = useCookies(['language'])

  useEffect(() => {
    const savedLanguage = cookies.language || 'en'
    // Завантажити переклади для мови за замовчуванням при старті
    changeLanguage(savedLanguage)
  }, [cookies.language])

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </Provider>
  );
}

export default App
