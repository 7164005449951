import SignupForm from '../modules/auth/SignupForm';

const Signup = () =>{
    return <>
        <div className='content'>
            <SignupForm />
        </div>
    </>
}

export default Signup;