import React from 'react'
import {createBrowserRouter} from "react-router-dom"
import LayoutPrivate from '../components/Layout/LayoutPrivate'
import LayoutAuth from '../components/Layout/LayoutAuth'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Verify from '../pages/Verify'
import Signup from '../pages/Signup'
import Profile from '../pages/Profile'
import Subscription from '../pages/Subscription'
import Friends from '../pages/Friends'
import SavedVideos from '../pages/SavedVideos'
import PopupsPage from '../pages/PopupsPage'
import SignupPhoto from '../pages/SignupPhoto'
import UsersList from '../pages/UsersList'
import FriendCall from '../pages/FriendCall'

import ResetPassword from '../pages/ResetPassword'
import ForgotPassword from '../pages/ForgotPassword'


export const router = createBrowserRouter([
    {'path': '/login', 'element': <LayoutAuth component={Login} />},
    {'path': '/signup', 'element': <LayoutAuth component={Signup} />},
    {'path': '/verify', 'element': <LayoutPrivate component={Verify} />},
    {'path': '/signup-photo', 'element': <LayoutPrivate component={SignupPhoto} />},
    {'path': '/', 'element': <LayoutPrivate component={Home} />},
    {'path': '/profile', 'element': <LayoutPrivate component={Profile} />},
    {'path': '/subscription', 'element': <LayoutPrivate component={Subscription} />},
    {'path': '/friends', 'element': <LayoutPrivate component={Friends} />},
    {'path': '/videos', 'element': <LayoutPrivate component={SavedVideos} />},
    {'path': '/popups', 'element': <LayoutPrivate component={PopupsPage} />},
    {'path': '/users-list', 'element': <LayoutPrivate component={UsersList} />},
    {'path': '/friend-call', 'element': <LayoutPrivate component={FriendCall} />},

    {'path': '/password-reset', 'element': <LayoutAuth component={ResetPassword} />},
    {'path': '/forgot-password', 'element': <LayoutAuth component={ForgotPassword} />},
])
