import {Link} from 'react-router-dom'
import gender_boy from '../../../assets/img/icons/gender-videochat-boy.svg'
import gender_girl from '../../../assets/img/icons/gender-videochat-girl.svg'
import addfriendIcon from '../../../assets/img/icons/add_friends.svg'
import { useDispatch, useSelector } from 'react-redux'
import { sendFriendRequest } from '../../../store/actions/friends/friends'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const TopControls = ({user}) => {
    const [isFriend, setFriend] = useState(false)
    const currentUser = useSelector(state => state.auth.currentUser)
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            const userFriends = user?.friends
            const newArr = []
            Object.entries(userFriends).forEach(([key, value]) => {
                newArr.push(value)
            })
            const isCurrentUserFriend = newArr.some(element => currentUser.id === element)
            setFriend(isCurrentUserFriend)
        } catch (e) {
            console.error('Error parsing JSON in top control!', e)
        }
    }, [currentUser, user])

    const onSendRequest = (e, id) => {
        e.preventDefault()
        dispatch(sendFriendRequest(id))
    }
    const { t } = useTranslation()

    return <div className='center_btns'>
        {user?.pet_gender && <div className='companion companion_gender text_medium'>
            <img src={user?.pet_gender === 'boy' ? gender_boy : gender_girl} alt="Gender" />
        </div>}
        <div className='companion text_medium'>
            <span>{t('home.gender_text')} |</span> <b className='subtitle_big'>{user?.name}</b>
        </div>
        {!isFriend && <Link to="/" className='add_friend btn' onClick={(e) => onSendRequest(e, user?.id)}>
            <span className='h4'>{t('home.add_friend_text')}</span>
            <img src={addfriendIcon} alt="Add friend" />
        </Link>}
    </div>
}

export default TopControls