import React from 'react'
import { useForm } from 'react-hook-form'
import Input from "../../ui/Input"
import Button from "../../ui/Button"
import { Link } from "react-router-dom"
import { initializeEcho, isObjEmpty } from '../../utils/Utils'
import { useDispatch, useSelector } from 'react-redux';
import { loginWithJWT } from '../../store/actions/auth/index'
import FormLoader from '../../ui/FormLoader'
import { useTranslation } from 'react-i18next'


const LoginForm = () => {

    const isAuthLoading = useSelector(state => state.auth.isAuthLoading)
    const authError = useSelector(state => state.auth.authError)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = data => {
        if (isObjEmpty(errors)) {
            dispatch(loginWithJWT(data)).then(() => {
                initializeEcho()
            })
        }
    }


    return <div className='form-wrap'>
        {isAuthLoading && <FormLoader />}
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <h2 className='form__title h2'>{t('authorization.title_log_in_form')}</h2>
            <Input
                type="text"
                name="email"
                label="Email"
                placeholder="Enter email"
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Email is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please, use correct email address"
                    }
                }}
            />
            <Input 
                type="password" 
                placeholder="Enter password" 
                name="password"
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Password is required",
                    minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters"
                    },
                    maxLength: {
                        value: 100,
                        message: "Password cannot be more than 100 characters"
                    }
                }}
            />
            <Button tag="button" disabled={isAuthLoading}>{t('authorization.log_in_btn')} </Button>
            <div className='question'>
                <span className='subtitle_medium'>{t('authorization.text_no_account')} <Link className='question__link subtitle_big subtitle_big_underline' to="/signup">{t('authorization.register_btn')} </Link></span>
            </div>
            <div className='question'>
                <span className='subtitle_medium'>Forgot your password? <Link className='question__link subtitle_big subtitle_big_underline' to="/forgot-password">Reset</Link></span>
            </div>
            {authError?.response?.status === 401 ? <p className='form__error'>{t('general.incorrect_credentials_text')}</p> : authError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
        </form>
    </div>
}

export default LoginForm