import actionTypes from '../action-types/friendsTypes';
const initialState = {
    isFriendsLoading: false,
    friendsList: null,
    friendsError: null,
    friendsRequests: null,
    allUsers: null
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.ALL_USERS) {
        return {
            ...state,
            allUsers: action.data,
        };
    }
    if (action.type === actionTypes.GETTING_FRIENDS_INFO) {
        return {
            ...state,
            friendsList: action.data,
        };
    }
    if (action.type === actionTypes.GETTING_FRIENDS_REUQESTS) {
        return {
            ...state,
            friendsRequests: action.data
        };
    }
    if (action.type === actionTypes.SEND_FRIEND_REQUEST) {
        return {
            ...state
        };
    }
    if (action.type === actionTypes.ACCEPT_FRIEND) {
        return {
            ...state
        };
    }
    if (action.type === actionTypes.DELETE_FRIEND) {
        return {
            ...state
        };
    }
    if (action.type === actionTypes.FRIENDS_LOADING) {
        return {
            ...state,
            isFriendsLoading: action.loading
        };
    }
    if (action.type === actionTypes.FRIENDS_ERROR) {
        return {
            ...state,
            friendsError: action.error
        };
    }
    return { ...state };
};

export default reducer;