import { useState } from 'react';
import arrowTabIcon from '../../../assets/img/icons/arrow_tab.svg';
import { useTranslation } from 'react-i18next'

const formatDateTime = (isoString) => {
    const date = new Date(isoString);

    // Форматировать дату как DD.MM.YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;

    // Форматировать время как HH:MM AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // hour '0' should be '12'
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return { date: formattedDate, time: formattedTime };
};

const TransactionHistory = ({ data }) => {
    const [isTableOpened, setTable] = useState(false);
    const { t } = useTranslation()

    return (
        <div className='transactions-history-card card-widget'>
            <div className='transactions-history-card__heading' onClick={() => setTable(!isTableOpened)}>
                <h3 className='h2'>{t('subscriptions.transactions_history_title')}</h3>
                <img src={arrowTabIcon} alt="arrow" className={`${isTableOpened ? 'upside-down' : ''}`} />
            </div>
            <div className={`transactions-history-card__content ${isTableOpened ? 'active' : ''}`}>
                <table>
                    <thead>
                        <tr>
                            <th className='subtitle_big'>{t('subscriptions.date')}</th>
                            <th className='subtitle_big'>{t('subscriptions.time')}</th>
                            <th className='subtitle_big'>{t('subscriptions.payment_card')}</th>
                            <th className='subtitle_big'>{t('subscriptions.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(record => {
                            const { date, time } = formatDateTime(record.create_date);
                            return (
                                <tr key={record.id}>
                                    <td className='h3'>{date}</td>
                                    <td className='h3'>{time}</td>
                                    <td className='h3'>{record.sender_card_mask2}</td>
                                    <td className='h3'>{record.amount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='table-mobile'>
                    {data.map(record => {
                        const { date, time } = formatDateTime(record.create_date);
                        return (
                            <div className='table-row' key={record.id}>
                                <div className='table-row_left'>
                                    <div className='table-row__item'>
                                        <span className='subtitle_medium'>{t('subscriptions.date')}</span>
                                        <h4 className='h4'>{date}</h4>
                                    </div>
                                    <div className='table-row__item'>
                                        <span className='subtitle_medium'>{t('subscriptions.payment_card')}</span>
                                        <h4 className='h4'>{record.sender_card_mask2}</h4>
                                    </div>
                                </div>
                                <div className='table-row_right'>
                                    <div className='table-row__item'>
                                        <span className='subtitle_medium'>{t('subscriptions.time')}</span>
                                        <h4 className='h4'>{time}</h4>
                                    </div>
                                    <div className='table-row__item'>
                                        <span className='subtitle_medium'>{t('subscriptions.amount')}</span>
                                        <h4 className='h4'>{record.amount}</h4>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TransactionHistory;
