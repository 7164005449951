const Checkbox = ({children, className, labelClasses, register, errors, validationSchema, name, ...props}) => {
    const classes = className ? className : ''
    const labels = labelClasses ? labelClasses : ''
    
    return <label className={`custom_chekbox ${labels}`}>
        <input 
            type="checkbox" 
            className={`send_information ${classes}`}
            autoComplete="off"
            {...register(name, validationSchema)}
            {...props}
        />
        <span className={`checkmark`}></span>
        <span className="custom_chekbox__text text_small">{children}I want to receive emails with information about product updates</span>
        {errors && errors[name]?.type === "required" && (
            <span className="error">{errors[name]?.message}</span>
        )}
    </label>
}
export default Checkbox