import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import { Navigate } from "react-router";
import { useEffect, useState } from "react";
import { addOnline, getUserInfo } from "../../store/actions/auth"
import Verify from '../../pages/Verify'

const LayoutPrivate = ({ component: Component }) => {
  const [online, setOnline] = useState(false)
  
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const currentUser = useSelector(state => state.auth.currentUser)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserInfo())
    if(!online) {
      dispatch(addOnline())
      setOnline(true)
    }
    setInterval(dispatch(addOnline), 60000)
  }, [dispatch])
  
  return isLoggedIn ? 
            currentUser?.email_verified ? <Layout><Component /></Layout> : 
            <Layout><Verify /></Layout> :
          <Navigate to={{pathname: '/login'}} />
}

export default LayoutPrivate