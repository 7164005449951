const TextArea = ({className, wrapperClasses, register, errors, validationSchema, name, ...props}) => {
    const wrapClass = wrapperClasses ? wrapperClasses : ''
    const inputClass = className ? className : ''

    return <div className={`input_div ${wrapClass}`}>
            <textarea 
                className={`input text_medium ${errors && errors[name] && 'error'} ${inputClass}`}
                {...register(name, validationSchema)}
                {...props}
            />
            {errors && errors[name]?.type === "required" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "pattern" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "minLength" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && errors[name]?.type === "maxLength" && (
                <span className="error">{errors[name]?.message}</span>
            )}
    </div>
}
export default TextArea