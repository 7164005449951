import {useEffect, useState, useRef} from 'react'
import warning from '../../assets/img/icons/warning-white.svg'
import menu from '../../assets/img/icons/mobile-menu.svg'
import menu_dark from '../../assets/img/icons/mobile-menu-dark.svg'
import menu_cross from '../../assets/img/icons/mobile-menu-cross.svg'
import {storageUrl} from '../../utils/Utils'
import { getBreeds } from '../../store/actions/breeds'
import { setVideochatBreed } from '../../store/actions/videochat'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const NavbarBreeds = ({isSubscribed}) => {
    const [breedActive, setBreedActive] = useState(false)
    const breedMenu = useRef(null)
    const breedBtn = useRef(null)
    const breedsList = useSelector(state => state.breeds.breedsList)
    const dispatch = useDispatch()
    const [cookies] = useCookies(['theme'])
    const { t } = useTranslation()
    
    useEffect(() => {
        dispatch(getBreeds())
    }, [getBreeds])

    const setBreed = (id) => {
        dispatch(setVideochatBreed(id))
        setBreedActive(false)
        Cookies.set('breed_id', id, { expires: 1, path: '/', secure: false, sameSite: 'strict' })
    }

    const closeBreedMenu = (e)=>{
        if(breedActive && !breedMenu.current?.contains(e.target) && !breedBtn.current?.contains(e.target)){
            setBreedActive(false)
        }
    }
    document.addEventListener("mousedown", closeBreedMenu)

  return (
    <div className='navbar__breed'>
        <button onClick={() => setBreedActive(!breedActive)} ref={breedBtn}>
            <img src={breedActive ? menu_cross : cookies?.theme === 'dark' ? menu : menu_dark} alt="breed menu" />
            <span className='h3'>{t('general.navbar_choose_breed')}</span>
        </button>
        <div className={`breed__dropdown ${breedActive ? 'active' : ''}`} ref={breedMenu}>
            <div className='breed_warning'>
                <img src={warning} alt="Warning" />
                <p className='h3'>{t('general.navbar_chat_by_breads_suscribtion')}</p>
            </div>
            {!isSubscribed ? <a href="/subscription" className="btn btn_primary flex justify-center">{t('general.navbar_breads_subscribe_now')}</a> : ''}
            <div className='breed__list'>
                {breedsList?.length && breedsList.map((b) => (
                <div className='breed__item' key={b.id}>
                    <div className='flex items-center'>
                        <div className="img-wrap"><img src={`${storageUrl}/${b.image}`} alt="Breed name" /></div>
                        <p className='subtitle_big'>{b.name}</p>
                    </div>
                    <button className='btn btn_primary' onClick={() => setBreed(b.id)}>{t('general.navbar_choose_breed_btn')}</button>
                    {/* {isSubscribed ? 
                        <button className='btn btn_primary' onClick={() => setBreed(b.id)}>Choose</button> : 
                        <button className='btn btn_primary' disabled>Choose</button>} */}
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default NavbarBreeds