// ** Redux Imports
import { combineReducers } from 'redux'
import auth from './auth'
import profile from './profile'
import breeds from './breeds'
import friends from './friends'
import videochat from './videochat'
import savedVideos from './savedVideos'

// ** Reducers Imports

const rootReducer = combineReducers({
    auth,
    profile,
    breeds,
    friends,
    videochat,
    savedVideos
})

export default rootReducer
