import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {useCookies} from 'react-cookie'
import { changeLanguage } from '../utils/i18n'
import { useTranslation } from 'react-i18next'
import arrow from '../assets/img/icons/arrow-down-profile.svg'
import arrow_dark from '../assets/img/icons/arrow-down-profile-dark.svg'

const Footer = () => {
    const [checked, setChecked] = useState(false)
    const [cookies, setCookie] = useCookies(['theme', 'language'])
    // const [cookieLang, setCookieLang] = useCookies(['language'])
    const { t } = useTranslation()
    
    if (!cookies) {
        setCookie('theme', 'dark')
        document.documentElement.setAttribute("theme", "dark")
        setChecked(false)
    }
    
    useEffect(() => {
        if (cookies?.theme === 'dark') {
            setChecked(false)
            document.documentElement.setAttribute("theme", "dark")
        } else {
            setChecked(true)
            document.documentElement.setAttribute("theme", "light")
        }
    }, [cookies])

    const switchTheme = e => {
        e.persist()
        setChecked(!checked)
        if(cookies?.theme === 'dark') {
            setCookie('theme', 'light')
            document.documentElement.setAttribute("theme", "light")
        } else {
            setCookie('theme', 'dark')
            document.documentElement.setAttribute("theme", "dark")
        }
    }

    const handleLanguageChange = async (lang) => {
        await changeLanguage(lang)
        setCookie('language', lang, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) })
    }

    return <footer>
            <div className="footer_rightpart">
                <div className='language'>
                    <span>{cookies?.language} <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="Arrow" /></span>
                    <div className="dropdown">
                        {cookies?.language === 'ua' ? 
                        <button onClick={() => handleLanguageChange('en')}>EN</button> : 
                        <button onClick={() => handleLanguageChange('ua')}>UA</button>}
                    </div>
                </div>
                <ul className="footer_menu">
                    <li className='subtitle_big'><Link to="/">{t('general.footer_menu1')}</Link></li>
                    <li className='subtitle_big'><Link to="/">{t('general.footer_menu2')}</Link></li>
                    <li className='subtitle_big'><Link to="/">{t('general.footer_menu3')}</Link></li>
                </ul>
                <div className="switch" id="theme-toggle">
                    <label htmlFor="toggle">
                        <input 
                            id="toggle" 
                            className="toggle-switch" 
                            type="checkbox" 
                            onChange={switchTheme}
                            checked={checked}
                        />
                        <div className="sun-moon"></div>
                        <div className="background"></div>
                    </label>
                </div>
            </div>
        </footer>
}

export default Footer;