import { useForm } from 'react-hook-form'
import Input from "../../ui/Input"
import Button from "../../ui/Button"
import Modal from 'react-modal'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import cross from '../../assets/img/icons/cross-white.svg'
import { updatePassword } from '../../store/actions/profile'
import FormLoader from '../../ui/FormLoader'
import { useTranslation } from 'react-i18next'

const PopupChangePassword = ({password, resetParent}) => {
    const { t } = useTranslation()
    const [isPasswordUpdated, setPasswordUpdated] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm()

    const passwordError = useSelector(state => state.profile.passwordError)
    const passwordLoading = useSelector(state => state.profile.passwordLoading)
    const dispatch = useDispatch()
    
    const newPassword = watch("password")

    const onSubmit = (data) => {
        dispatch(updatePassword(data)).then(response => {
            console.log("Пароль успішно оновлено!", response);
            setPasswordUpdated(true)
            setTimeout(() => {
                setPasswordUpdated(false)
            }, 5000)
            reset()
            resetParent()
        })
        .catch(error => {
            console.error("Помилка оновлення пароля:", error);
        });
    }

    return (
        <div className="popup__change-password">
            <h2 className='popup__heading h2'>{t('general.change_password')}</h2>
            <span className='popup__description subtitle_medium'>{t('general.change_password_info')}</span>
            {passwordLoading ? <FormLoader /> : 
            <form onSubmit={handleSubmit(onSubmit)} className='popup__form'>
                <Input
                    placeholder="Enter password"
                    name="old_password"
                    type="password"
                    register={register}
                    errors={errors}
                    validationSchema={{ 
                        required: "Password is required",
                        minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters"
                        },
                        maxLength: {
                            value: 40,
                            message: "Password cannot be more than 40 characters"
                        }
                    }}
                />
                <Input
                    placeholder="Enter new password"
                    name="password"
                    type="password"
                    register={register}
                    errors={errors}
                    defaultValue={password}
                    validationSchema={{ 
                        required: "Password is required",
                        minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters"
                        },
                        maxLength: {
                            value: 40,
                            message: "Password cannot be more than 40 characters"
                        }
                    }}
                />
                <Input
                    placeholder="Confirm new password"
                    name="password_confirmation"
                    type="password"
                    errors={errors}
                    passwordToCompare={newPassword}
                    register={register}
                />
                <Button to="/"
                    tag="button" 
                    type="primary" 
                >{t('general.confirm')}</Button>
                <div className='question'>
                    <span className='subtitle_medium'>{t('general.forgot_password')} <Link className='question__link subtitle_big subtitle_big_underline' to="/">{t('profile.recover')}</Link></span>
                </div>
                {passwordError?.response?.status === 422 ? <p className='form__error'>{t('general.incorrect_data_text')}</p> : passwordError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
                {isPasswordUpdated && <p className='success'>{t('general.password_update_successfully')}</p>}
            </form>
            }
        </div>
    )
}

const ChangePasswordForm = () => {
    const { t } = useTranslation()
    const [changePasswordPopup, setChangePasswordPopup] = useState(false)
    const [password, setPassword] = useState('')

    const openChangePasswordModal = () => {
        setChangePasswordPopup(true)
    }
    const closeChangePasswordModal = () => {
        setChangePasswordPopup(false)
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()
    const onSubmit = () => {
        openChangePasswordModal()
        reset()
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <Input 
                type="password" 
                placeholder="Password" 
                name="password"
                errors={errors}
                register={register}
                onChange={(e) => setPassword(e.target.value)}
                validationSchema={{ 
                    required: "Password is required",
                    minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters"
                    },
                    maxLength: {
                        value: 40,
                        message: "Password cannot be more than 40 characters"
                    }
                }}
            />
            <Button tag="button" type="ghost">{t('profile.change')}</Button>
        </form>
        <Modal
            isOpen={changePasswordPopup}
            onRequestClose={closeChangePasswordModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeChangePasswordModal}><img src={cross} alt="Close" /></div>
                <PopupChangePassword password={password} resetParent={() => setPassword('')} />
            </div>
        </Modal>
    </>
}
export default ChangePasswordForm