import { useEffect } from 'react'
import FriendsList from './FriendsList'
import { getFriends } from '../../store/actions/friends/friends'
import { useDispatch, useSelector } from 'react-redux'

const FriendsMain = () => {
    const friendsList = useSelector(state => state.friends.friendsList)
    const friendsError = useSelector(state => state.friends.friendsError)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFriends())
    }, [dispatch])

    return <div className='card-widget card-widget__friends-card'>
        <FriendsList status="online" friends={friendsList?.online} />
        <FriendsList status="offline" friends={friendsList?.offline} />
        {friendsError?.message && <p className='error'>{friendsError.message}</p>} 
    </div>
}
export default FriendsMain