import actionTypes from '../action-types/profileUpdateTypes';
const initialState = {
    profileLoading: false,
    currentUser: null,
    profileError: null,
    profilePhoto: null,
    passwordError: null,
    passwordLoading: null,
    emailError: null,
    emailLoading: null,
    profilePhotoError: null,
    profilePhotoLoading: null,
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.UPDATE_PROFILE_INFO) {
        return {
            ...state,
            currentUser: action.currentUser
        };
    }
    if (action.type === actionTypes.UPDATE_PROFILE_LOADING) {
        return {
            ...state,
            profileLoading: action.loading
        };
    }
    if (action.type === actionTypes.UPDATE_PROFILE_ERROR) {
        return {
            ...state,
            profileError: action.error
        };
    }
    if (action.type === actionTypes.UPDATE_PROFILE_PHOTO) {
        return {
            ...state,
            profilePhoto: action.profilePhoto
        };
    }
    if (action.type === actionTypes.UPLOAD_PHOTO_LOADING) {
        return {
            ...state,
            profilePhotoLoading: action.loading
        };
    }
    if (action.type === actionTypes.UPLOAD_PHOTO_ERROR) {
        return {
            ...state,
            profilePhotoError: action.error
        };
    }
    if (action.type === actionTypes.GETTING_USER_INFO) {
        return {
            ...state,
            currentUser: action.currentUser
        };
    }
    if (action.type === actionTypes.UPDATE_PASSWORD) {
        return {
            ...state
        };
    }
    if (action.type === actionTypes.PASSWORD_LOADING) {
        return {
            ...state,
            passwordLoading: action.loading
        };
    }
    if (action.type === actionTypes.PASSWORD_ERROR) {
        return {
            ...state,
            passwordError: action.error
        };
    }
    if (action.type === actionTypes.UPDATE_EMAIL) {
        return {
            ...state
        };
    }
    if (action.type === actionTypes.EMAIL_LOADING) {
        return {
            ...state,
            emailLoading: action.loading
        };
    }
    if (action.type === actionTypes.EMAIL_ERROR) {
        return {
            ...state,
            emailError: action.error
        };
    }
    return { ...state };
};

export default reducer;