import actionTypes from '../action-types/videochatTypes';
const initialState = {
    isVideochatLoading: false,
    videochatRemoteId: null,
    videochatError: null,
    videochatBreedId: null,
    selectedBreed: null,
    friendOnCall: null
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.GETTING_REMOTE_PEER) {
        return {
            ...state,
            videochatRemoteId: action.id,
        };
    }
    if (action.type === actionTypes.SET_BREED) {
        return {
            ...state,
            selectedBreed: action.data
        };
    }
    if (action.type === actionTypes.END_CALL) {
        return {
            ...state,
        };
    }
    if (action.type === actionTypes.IS_ON_CALL) {
        return {
            ...state,
        };
    }
    if (action.type === actionTypes.FRIEND_ON_CALL) {
        return {
            ...state,
            friendOnCall: action.data
        };
    }
    if (action.type === actionTypes.VIDEOCHAT_LOADING) {
        return {
            ...state,
            isVideochatLoading: action.loading
        };
    }
    if (action.type === actionTypes.VIDEOCHAT_ERROR) {
        return {
            ...state,
            videochatError: action.error
        };
    }
    return { ...state };
};

export default reducer;