import LoginForm from '../modules/auth/LoginForm'

const Login = () => {
    
    return <div className='content'>
            <LoginForm />
        </div>
}

export default Login;
