import React, {useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import notification from '../../assets/img/icons/bell.svg'
import arrow from '../../assets/img/icons/arrow-down-profile.svg'
import arrow_dark from '../../assets/img/icons/arrow-down-profile-dark.svg'
import cross from '../../assets/img/icons/cross-white.svg'
import avatar from '../../assets/img/icons/avatar-example.png'
import Modal from 'react-modal'
import { PopupLogout } from '../Popups/Popups'
import { connect } from 'react-redux'
import ActionTypes from '../../store/action-types'
import { storageUrl } from '../../utils/Utils'
import NavbarNotifications from './NavbarNotifications'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const NavbarElements = ({user, onUserLogout}) => {

    const [notificationPopup, setNotificationPopupActive] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [friendsRequests, setFriendsRequests] = useState([])
    const [invitesRequests, setInvitesRequests] = useState([])
    const notificationMenu = useRef(null)
    const notificationBtn = useRef(null)
    const [cookies] = useCookies(['theme'])
    const { t } = useTranslation()

    Modal.setAppElement('#root')
    const openModal = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }
    
    const closeModal = () => {
        setIsOpen(false)
    }

    const toggleNotifications = () => {
        setNotificationPopupActive(!notificationPopup)
    }

    const handleDataFromChild = (data) => {
        setFriendsRequests(data)
    }
    const handleInvitesFromChild = (data) => {
        setInvitesRequests(data)
    }

    const closeNotificationsMenu = (e)=>{
        if(notificationPopup && !notificationMenu.current?.contains(e.target) && !notificationBtn.current?.contains(e.target)){
            setNotificationPopupActive(false)
        }
    }
    document.addEventListener("mousedown", closeNotificationsMenu)

  return (
    <div className='navbar__right'>
        <Link to="/subscription" className="btn btn_primary mobile-hidden">{t('general.navbar_subscribe')}</Link>
        <div className='navbar__notification'>
            <button 
                className={`${friendsRequests?.length || invitesRequests?.length ? 'unread' : ''}`} 
                onClick={() => setNotificationPopupActive(!notificationPopup)}
                ref={notificationBtn}    
            >
                <img src={notification} alt="Notification" />
            </button>
            <div ref={notificationMenu}>
                <NavbarNotifications
                    notificationPopup={notificationPopup}
                    toggleNotifications={toggleNotifications}
                    sendDataToParent={handleDataFromChild}
                    sendInvitesToParent={handleInvitesFromChild}
                />
            </div>
        </div>
        <div className='navbar__profile'>
            <div className='profile-logo-wrap'>
                {user?.image ? <img src={`${storageUrl}/${user.image}`} alt={user.name} /> : <img src={avatar} alt="Avatar" />}
            </div>
            <div className='flex flex-col'>
                <span className='subtitle_medium mobile-hidden'>{t('general.navbar_profile')}</span>
                <span className='h3'><span className='mobile-hidden'>{user?.name}</span> <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="Arrow" /></span>
            </div>
            <div className="dropdown">
                <Link to="/profile">{t('general.navbar_menu_profile')}</Link>
                <Link to="/friends">{t('general.navbar_menu_friends')}</Link>
                <Link to="/subscription">{t('general.navbar_menu_subscribes')}</Link>
                <Link to="/videos">{t('general.navbar_menu_videos')}</Link>
                <Link to="#logout-popup" onClick={openModal}>{t('general.navbar_menu_logout')}</Link>
            </div>
        </div>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeModal}><img src={cross} alt="Close" /></div>
                <PopupLogout onCloseModal={closeModal} onUserLogout={onUserLogout} />
            </div>
        </Modal>
    </div>
  )
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
    onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
})
export default connect(mapStateToProps, mapDispatchToProps)(NavbarElements)