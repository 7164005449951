// import { configureStore } from '@reduxjs/toolkit';

// const store = configureStore({reducer: rootReducer});

// export default store;

import rootReducer from './reducers/rootReducer';
import {createStore, applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';

const middlewares = [thunk];

const store = createStore(rootReducer, applyMiddleware(...middlewares));


export default store;