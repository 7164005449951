import { useForm } from 'react-hook-form'
import Input from "../../ui/Input"
import Button from "../../ui/Button"
import { updateEmail } from '../../store/actions/profile'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import cross from '../../assets/img/icons/cross-white.svg'
import parrotLogout from '../../assets/img/icons/parrot_popup_logout.svg'
import { useTranslation } from 'react-i18next'

const ChangeEmailForm = () => {
    const [isConfirmPopup, setConfirmPopup] = useState(false)
    const [emailFormData, setEmailFormData] = useState(false)
    const [isEmailUpdated, setEmailUpdated] = useState(false)
    const [emailSameError, setEmailSameError] = useState(false)
    const { t } = useTranslation()

    const currentUser = useSelector(state => state.profile.currentUser)
    const emailError = useSelector(state => state.profile.emailError)

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        currentUser?.email && setValue('email', currentUser.email)
    }, [currentUser])

    const onSubmit = (data) => {
        if(currentUser.email === data.email) {
            setEmailSameError(true)
            setTimeout(() => {
                setEmailSameError(false)
            }, 5000)
        } else {
            setEmailSameError(false)
            setEmailFormData(data)
            setConfirmPopup(true)
        }
    }

    const onUpdateEmail = () => {
        dispatch(updateEmail(emailFormData)).then(response => {
            console.log("Емейл успішно оновлено!", response);
            setEmailUpdated(true)
            closeConfirmModal()
            window.location.reload()
            setTimeout(() => {
                setEmailUpdated(false)
            }, 5000)
            reset()
        }).catch(error => {
            console.error("Помилка оновлення емейлу:", error);
            closeConfirmModal()
        })
    }

    const closeConfirmModal = () => {
        setConfirmPopup(false)
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <Input 
                type="text" 
                placeholder="E-mail" 
                name="email"
                errors={errors}
                register={register}
                // defaultValue={currentUser?.email}
                validationSchema={{ 
                    required: "Email is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please, use correct email address"
                    }
                }}
            />
            <Button tag="button" type="ghost" >{t('profile.change')}</Button>
            {emailError?.response?.status === 422 ? <p className='form__error'>{t('general.incorrect_data_text')}</p> : emailError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
            {isEmailUpdated && <p className='success text-left w-full mb-2 mt-[-10px] pl-[13px]'>{t('profile.email_update_successfully')}</p>}
            {/* {!isEmailSame && <p className='form__error text-left w-full pl-[13px] relative top-[-20px]'>Email is the same</p>} */}
            {emailSameError && <span className='error text-left w-full mt-[-20px] mb-2'>{t('profile.repeate_email')}</span>}
        </form>
        <Modal
            isOpen={isConfirmPopup}
            onRequestClose={closeConfirmModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeConfirmModal}><img src={cross} alt="Close" /></div>
                <div className="popup__logout">
                    <h2 className='popup__heading h2'>{t('profile.confirmation_update_email')}</h2>
                    <span className='popup__logout-subheading h4'>{t('profile.question_update_email')}</span>
                    <div className='popup__icon-parrot'>
                        <img src={parrotLogout} alt="parrot" />
                    </div>
                    <div className="btns-flex">
                        <Button tag="button"
                            type="secondary"
                            onClick={closeConfirmModal} 
                        >{t('general.cancel')}</Button>
                        <Button 
                            tag="button"
                            type="primary" 
                            onClick={onUpdateEmail}
                        >{t('general.configrm_button_text')}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}
export default ChangeEmailForm