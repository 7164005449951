import { Link } from "react-router-dom"
import nextIcon from '../assets/img/icons/next_arrow.svg'
import prevIcon from '../assets/img/icons/prev_arrow.svg'

const Pagi = ({data, currentPage, handlePageChange, onPrevPage, onNextPage}) => {
    const pageCount = data?.length

    // return 
    // <div className="pagination-wrap">
    //     <Link to="/" className="prev arrow">
    //         <img src={prevIcon} alt="previous button"/>
    //     </Link>
    //     <div className="pagination d-flex ai-c">
    //         <Link to="/" className='h4'>1</Link>
    //         <Link to="/" className="active h4">2</Link>
    //         <span className='h4'>...</span>
    //         <Link className='h4'>12</Link>
    //     </div>
    //     <Link to="/" className="next arrow">
    //         <img src={nextIcon} alt="next button"/>
    //     </Link>
    // </div>

    return <div className="pagination-wrap">
        {currentPage !== 1 && <Link 
            to="/" 
            className="prev arrow"
            onClick={(e) => onPrevPage(e)}
        >
            <img src={prevIcon} alt="previous button"/>
        </Link>}
        <div className="pagination d-flex ai-c">
            {Array.from({ length: pageCount }, (_, index) => (
                <Link
                    key={index}
                    onClick={(e) => handlePageChange(e, index)}
                    to="/" 
                    className={`h4 ${currentPage === (index+1) ? 'active' : ''}`}
                >{index + 1}</Link>
        ))}
        </div>
        {currentPage !== pageCount && <Link 
            to="/" 
            className="next arrow"
            onClick={(e) => onNextPage(e)}
        >
            <img src={nextIcon} alt="next button"/>
        </Link>}
    </div>
}
export default Pagi