import { Link } from 'react-router-dom'
import arrow from '../assets/img/icons/arrow.svg'
import profileIcon from '../assets/img/icons/personal_info_icon.svg'
import securityIcon from '../assets/img/icons/security_icon.svg'
import ProfileInfoForm from '../modules/profile/ProfileInfoForm'
import ChangeEmailForm from '../modules/profile/ChangeEmailForm'
import ChangePasswordForm from '../modules/profile/ChangePasswordForm'
import PhotoForm from '../modules/profile/PhotoForm'
import { useTranslation } from 'react-i18next'
import {useCookies} from 'react-cookie'
import arrow_dark from '../assets/img/icons/arrow_dark.svg' 

const Profile = () => {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['theme', 'language'])
    return <div className='content'>
            <div className="container">
                <Link to="/" className='back-link'>
                    <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="back" />
                    <span className='subtitle_big'>{t('general.back_chat')}</span>
                </Link>
                <div className='profile-card card-widget'>
                    <h2 className='h2 card-widget__heading'>{t('profile.profile_photo')}</h2>
                    <PhotoForm />
                </div>
                <div className='security-card card-widget relative'>
                    <h2 className='h2 card-widget__heading'>{t('profile.security_personal_information')}</h2>
                    <div className='flex-profile'>
                        <div className='flex-profile__col'>
                            <div className='title-col'>
                                <img src={profileIcon} alt="user icon" />
                                <span className='subtitle_medium'>{t('profile.personal_info')}</span>
                            </div>
                            <ProfileInfoForm />
                        </div>
                        <div className='flex-profile__col'>
                            <div className='title-col'>
                                <img src={securityIcon} alt="security icon" />
                                <span>{t('profile.account_info')}</span>
                            </div>
                            <ChangeEmailForm />
                            <ChangePasswordForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default Profile
