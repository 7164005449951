import React from "react"
import '../../App.scss'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer'
import { useAuth } from "../../hooks/useAuth"

const Layout = ({ children, className }) => {
  const classes = className ? className : ''
  useAuth()  

  return (
    <div className={`layout ${classes}`}>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}


export default React.memo(Layout)