import actionTypes from '../../action-types/profileUpdateTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const uploadProfilePhoto = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: true })
  dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: null })

  return http
    .post(apiConfig.profile.update, data)
    .then(response => {
        dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: false })
        dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_PHOTO,
          currentUser: response.data
        })
        // window.location.href='/'
      }
      return response
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: false })
      dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: err })
      throw err
    })
}

export const updateProfile = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: true })
  dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: null })
  return http
    .post(apiConfig.profile.update, data)
    .then(response => {
        dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: false })
        dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_INFO,
          currentUser: response.data
        })
      }
      return response
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: false })
      dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: err })
      throw err
    })
}

export const updatePassword = (data) => (dispatch) => {
  dispatch({ type: actionTypes.PASSWORD_LOADING, loading: true })
  dispatch({ type: actionTypes.PASSWORD_ERROR, error: null })
  return http.patch(apiConfig.profile.updatePassword, data)
    .then(response => {
        dispatch({ type: actionTypes.PASSWORD_LOADING, loading: false });
        dispatch({ type: actionTypes.PASSWORD_ERROR, error: null });
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_PASSWORD,
          });
        }
        return response  // Повернення результату для подальшої обробки
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.PASSWORD_LOADING, loading: false });
      dispatch({ type: actionTypes.PASSWORD_ERROR, error: err });
      throw err  // Перекидання помилки для подальшого ланцюжка catch
    });
}

export const updateEmail = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: true })
  dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: null })
  return http.patch(apiConfig.profile.updateEmail, data)
    .then(response => {
        dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: false });
        dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: null });
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_EMAIL,
          });
        }
        return response;
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.UPDATE_PROFILE_LOADING, loading: false });
      dispatch({ type: actionTypes.UPDATE_PROFILE_ERROR, error: err });
      throw err;
    });
}

export const deleteProfilePhoto = () => (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: true })
  dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: null })

  return http
    .delete(apiConfig.profile.deletePhoto)
    .then(response => {
        dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: false })
        dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_PHOTO
        })
      }
      return response
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.UPLOAD_PHOTO_LOADING, loading: false })
      dispatch({ type: actionTypes.UPLOAD_PHOTO_ERROR, error: err })
      throw err
    })
}