import { useState } from "react"
import Modal from 'react-modal'
import cross from '../assets/img/icons/cross-white.svg'
import arrowBack from '../assets/img/icons/arrow_back.svg'
import {PopupProfilePhoto, PopupLogout, PopupRegisterSubscription, PopupCongratulations, PopupCancelSuscription, PopupChangePassword, PopupReplaceCard, PopupRecoverPassword, PopupVerifyNewPassword, PopupDeletePhoto, PopupChangeEmail, PopupUnfollowFriend, PopupConfirmationCode, PopupUploadPhoto} from '../components/Popups/Popups'
import Button from "../ui/Button"

const PopupsPage = () => {
    const [profilePhotoPopup, setProfilePhotoPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)
    const [registerSubscriptionPopup, setRegisterSubscriptionPopup] = useState(false)
    const [congratulationsPopup, setCongratulationsPopup] = useState(false)
    const [cancelSubscriptionPopup, setCancelSubscriptionPopup] = useState(false)
    const [changePasswordPopup, setChangePasswordPopup] = useState(false)
    const [replaceCardPopup, setReplaceCardPopup] = useState(false)
    const [recovePasswordPopup, setRecoverPasswordPopup] = useState(false)
    const [verifyNewEmailPopup, setVerifyNewEmailPopup] = useState(false)
    const [deletePhotoPopup, setDeletePhotoPopup] = useState(false)
    const [changeEmailPopup, setChangeEmailPopup] = useState(false)
    const [unfollowFriendPopup, setUnfollowFriendPopup] = useState(false)
    const [confirmationCodePopup, setConfirmationCodePopup] = useState(false)
    const [uploadPhotoPopup, setUploadPhotoPopup] = useState(false)
    Modal.setAppElement('#root')

    //Open/close photo modal
    const openLogoutModal = (e) => {
        e.preventDefault()
        setLogoutPopup(true)
    }
    const closeLogoutModal = () => {
        setLogoutPopup(false)
    }

    //Open/close photo modal
    const openProfilePhotoModal = (e) => {
        e.preventDefault()
        setProfilePhotoPopup(true)
    }
    const closeProfilePhotoModal = () => {
        setProfilePhotoPopup(false)
    }

    //Open/close photo modal
    const openRegisterSubscriptionModal = (e) => {
        e.preventDefault()
        setRegisterSubscriptionPopup(true)
    }
    const closeRegisterSubscriptionModal = () => {
        setRegisterSubscriptionPopup(false)
    }


    //Open/close photo modal
    const openCongratulationsModal = (e) => {
        e.preventDefault()
        setCongratulationsPopup(true)
    }
    const closeCongratulationsModal = () => {
        setCongratulationsPopup(false)
    }

    //Open/close photo modal
    const openCancelSuscriptionModal = (e) => {
        e.preventDefault()
        setCancelSubscriptionPopup(true)
    }
    const closeCancelSuscriptionModal = () => {
        setCancelSubscriptionPopup(false)
    }

    //Open/close photo modal
    const openChangePasswordModal = (e) => {
        e.preventDefault()
        setChangePasswordPopup(true)
    }
    const closeChangePasswordModal = () => {
        setChangePasswordPopup(false)
    }

    //Open/close photo modal
    const openReplaceCardModal = (e) => {
        e.preventDefault()
        setReplaceCardPopup(true)
    }
    const closeReplaceCardModal = () => {
        setReplaceCardPopup(false)
    }

    //Open/close photo modal
    const openRecoverPasswordModal = (e) => {
        e.preventDefault()
        setRecoverPasswordPopup(true)
    }
    const closeRecoverPasswordModal = () => {
        setRecoverPasswordPopup(false)
    }

     //Open/close photo modal
     const openVerifyNewEmailModal = (e) => {
        e.preventDefault()
        setVerifyNewEmailPopup(true)
    }
    const closeVerifyNewEmailModal = () => {
        setVerifyNewEmailPopup(false)
    }

     //Open/close photo modal
     const openDeletePhotoModal = (e) => {
        e.preventDefault()
        setDeletePhotoPopup(true)
    }
    const closeDeletePhotoModal = () => {
        setDeletePhotoPopup(false)
    }

    
     //Open/close photo modal
     const openChangeEmailModal = (e) => {
        e.preventDefault()
        setChangeEmailPopup(true)
    }
    const closeChangeEmailModal = () => {
        setChangeEmailPopup(false)
    }

     //Open/close photo modal
     const openUnfollowFriendModal = (e) => {
        e.preventDefault()
        setUnfollowFriendPopup(true)
    }
    const closeUnfollowFriendModal = () => {
        setUnfollowFriendPopup(false)
    }

     //Open/close photo modal
     const openConfirmationCodeModal = (e) => {
        e.preventDefault()
        setConfirmationCodePopup(true)
    }
    const closeConfirmationCodeModal = () => {
        setConfirmationCodePopup(false)
    }

     //Open/close photo modal
     const openUploadPhotoModal = (e) => {
        e.preventDefault()
        setUploadPhotoPopup(true)
    }
    const closeUploadPhotoModal = () => {
        setUploadPhotoPopup(false)
    }

    return <>
        <div className="m-2">
            <Button tag="button" onClick={openLogoutModal}>Logout</Button>
            <Modal
                isOpen={logoutPopup}
                onRequestClose={closeLogoutModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeLogoutModal}><img src={cross} alt="Close" /></div>
                    <PopupLogout onCloseModal={closeLogoutModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openProfilePhotoModal}>Profile photo</Button>
            <Modal
                isOpen={profilePhotoPopup}
                onRequestClose={closeProfilePhotoModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeProfilePhotoModal}><img src={cross} alt="Close" /></div>
                    <PopupProfilePhoto onCloseModal={closeProfilePhotoModal}/>
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openRegisterSubscriptionModal}>Register subsription</Button>
            <Modal
                isOpen={registerSubscriptionPopup}
                onRequestClose={closeRegisterSubscriptionModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeRegisterSubscriptionModal}><img src={cross} alt="Close" /></div>
                    <PopupRegisterSubscription onCloseModal={closeRegisterSubscriptionModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openCancelSuscriptionModal}>Cancel subsription</Button>
            <Modal
                isOpen={cancelSubscriptionPopup}
                onRequestClose={closeCancelSuscriptionModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeCancelSuscriptionModal}><img src={cross} alt="Close" /></div>
                    <PopupCancelSuscription onCloseModal={closeCancelSuscriptionModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openCongratulationsModal}>Congratulations subsription</Button>
            <Modal
                isOpen={congratulationsPopup}
                onRequestClose={closeCongratulationsModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeCongratulationsModal}><img src={cross} alt="Close" /></div>
                    <PopupCongratulations onCloseModal={closeCongratulationsModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openChangePasswordModal}>Change password</Button>
            <Modal
                isOpen={changePasswordPopup}
                onRequestClose={closeChangePasswordModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeChangePasswordModal}><img src={cross} alt="Close" /></div>
                    <PopupChangePassword />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openReplaceCardModal}>Replace card</Button>
            <Modal
                isOpen={replaceCardPopup}
                onRequestClose={closeReplaceCardModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeReplaceCardModal}><img src={cross} alt="Close" /></div>
                    <PopupReplaceCard onCloseModal={closeReplaceCardModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openRecoverPasswordModal}>Recover password</Button>
            <Modal
                isOpen={recovePasswordPopup}
                onRequestClose={closeRecoverPasswordModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeRecoverPasswordModal}><img src={cross} alt="Close" /></div>
                    <PopupRecoverPassword />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openVerifyNewEmailModal}>Verify your new E-mail</Button>
            <Modal
                isOpen={verifyNewEmailPopup}
                onRequestClose={closeVerifyNewEmailModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeVerifyNewEmailModal}><img src={cross} alt="Close" /></div>
                    <PopupVerifyNewPassword />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openDeletePhotoModal}>Delete a photo</Button>
            <Modal
                isOpen={deletePhotoPopup}
                onRequestClose={closeDeletePhotoModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeDeletePhotoModal}><img src={cross} alt="Close" /></div>
                    <PopupDeletePhoto onCloseModal={closeDeletePhotoModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openChangeEmailModal}>Change Email</Button>
            <Modal
                isOpen={changeEmailPopup}
                onRequestClose={closeChangeEmailModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeChangeEmailModal}><img src={cross} alt="Close" /></div>
                    <PopupChangeEmail />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openUnfollowFriendModal}>Unfollow Friend</Button>
            <Modal
                isOpen={unfollowFriendPopup}
                onRequestClose={closeUnfollowFriendModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeUnfollowFriendModal}><img src={cross} alt="Close" /></div>
                    <PopupUnfollowFriend onCloseModal={closeUnfollowFriendModal} />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openConfirmationCodeModal}>Confirmation code</Button>
            <Modal
                isOpen={confirmationCodePopup}
                onRequestClose={closeConfirmationCodeModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content" >
                    <div className="popup__close" onClick={closeConfirmationCodeModal}><img src={cross} alt="Close" /></div>
                    <PopupConfirmationCode />
                </div>
            </Modal>
        </div>
        <div className="m-2">
            <Button tag="button" onClick={openUploadPhotoModal}>Upload photo</Button>
            <Modal
                isOpen={uploadPhotoPopup}
                onRequestClose={closeUploadPhotoModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content popup__content_small" >
                    <div className="popup__close popup__close_left" onClick={closeUploadPhotoModal}><img src={arrowBack} alt="Close" /></div>
                    <PopupUploadPhoto onCloseModal={closeUploadPhotoModal} />
                </div>
            </Modal>
        </div>
    </>
}
export default PopupsPage