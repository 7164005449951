import actionTypes from '../../action-types/savedVideosTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const getSavedVideos = () => async (dispatch) => {
  dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: true })
  dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: null })

  return http
    .get(apiConfig.savedVideos.get)
    .then(response => {
        dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: false })
        dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.SAVED_VIDEOS_INFO,
          data: response.data
        })
      }
      return response
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: false })
      dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: err })
      throw err
    })
}

export const deleteVideo = (id) => (dispatch) => {
    dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: true })
    dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: null })
    const data = {
        videoId: id
    }
    http
      .delete(apiConfig.savedVideos.delete, {data})
      .then(response => {
          dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: false })
          dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: null })
        if (response?.data) {
          dispatch(getSavedVideos())
        }
      })
      .catch(err => {
        toast.error(err.message);
        dispatch({ type: actionTypes.SAVED_VIDEOS_LOADING, loading: false })
        dispatch({ type: actionTypes.SAVED_VIDEOS_ERROR, error: err })
      })
}