import axios from 'axios'
import store from '../store/store'
import ActionTypes from '../store/action-types'

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
})
http.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

http.interceptors.response.use(
    response => response,
    (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            store.dispatch({ type: ActionTypes.LOGOUT_USER })
            return http(originalRequest)
        } else if (error.response.status === 401) {
            store.dispatch({ type: ActionTypes.LOGOUT_USER })
        } else {
            return Promise.reject(error);
        }
    }
)

export { http };
